import React, { FC } from "react";
import "./BigTitle.scss";

interface BigTitleProps extends React.HTMLAttributes<HTMLOrSVGElement> {
  level?: keyof JSX.IntrinsicElements;
  className?: string,
  text?: string,
}

const BigTitle: FC<BigTitleProps> = ({ level, text }) => {
  const Tag = level as keyof JSX.IntrinsicElements;
  return <Tag className="BigTitle">{text}</Tag>;
};

BigTitle.defaultProps = {
  text: "Title",
  level: "h1"
}

export default BigTitle;
