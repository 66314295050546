import axios from "axios";

function getCustomerActivities (originalData , customerActivities, setCustomerActivities) {

  let findId = Array.from(originalData.map(function(element) {
    return element.id;
  }));

  let copy = Object.assign({}, customerActivities);

  axios.get(
    `/api/userInfo/getActivityWithIllustrations`, {
      params: {
        illustrationIds: findId
      }}
  ).then((response) => {
    if (response.data.success) {
      const activities =  response.data.result.activitys;
      activities.forEach((el)=> copy[el.illustrationId] = { isLiked: el.isLiked, isDownloaded: el.isDownloaded } );
      setCustomerActivities(copy);
    }

  })
    .catch((error) => {
      console.log(error);
    });
}

export default getCustomerActivities;
