import  { useEffect } from "react";
import { useHistory } from "react-router-dom";

import {userLogout} from "../../reducers/userSlice";

// Redux
import { useDispatch } from 'react-redux';

function LogOut(props) {
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(userLogout);
    history.push('/login');
  })

  return (null);
}

export default LogOut;
