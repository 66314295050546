import React, { useEffect, useState, Fragment } from 'react';
import { Link } from 'react-router-dom';

import { Table } from 'antd';
import { v4 as uuidv4 } from 'uuid';

import MainTitle from '../../components/MainTitle.tsx';
import SettingsSidebar from '../../components/SettingsSidebar';
import EmptyPageFiller from "../../components/EmptyPageFiller";

//Common js
import { settingsSidebarItems } from '../../lib/settingsSidebarItems';
import { getRequest } from '../../helpers/apiRequestsHelper';
import getOrderStatusName from '../../lib/orderStatusIdNameCompare';

import './index.scss';

const orderTableColumns = [
  {
    title: '#',
    dataIndex: 'id',
    key: 'id'
  },
  {
    title: 'Date',
    dataIndex: 'createdDate',
    key: 'createdDate'
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status'
  },
  {
    title: 'Total',
    dataIndex: 'totalPrice',
    key: 'totalPrice'
  },
  {
    title: '',
    dataIndex: '',
    key: 'view',
    render: (row) => <Link to={`/order/${row.id}`} className='settingsTable__link'>View</Link>,
  },
];

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getOrdersList = () => {
    getRequest ('api/order/list')
      .then(data => {
        if (data.success) {
          data.result.orders.map(order => {
            order.key = uuidv4();
            order.status = getOrderStatusName(order.status);
            order.totalPrice = `$ ${order.totalPrice}`;
            return true;
          });
          setOrders(data.result.orders);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      })
      .catch(err => {
        setIsLoading(false);
      })
  }

  useEffect(() => {
    getOrdersList();
  }, [])


  return (
    <div className='container Order'>
      <div className='row'>
        <div className='col-12 col-md-2'>
          <SettingsSidebar items={settingsSidebarItems} />
        </div>
        <div className='col-12 offset-0 col-md-9 offset-md-1'>
          {!isLoading ?
           orders.length ?
            <Fragment>
              <MainTitle text='Orders' />
              <Table
                className='settingsTable'
                columns={orderTableColumns}
                dataSource={orders}
                pagination={false}
              />
            </Fragment>
            : <EmptyPageFiller
              text="You have no orders yet"
              StandartImage={'https://storytale-public2.b-cdn.net/static/assets/account-app/assets/img/EmptyOrder.png'}
              BigImage={'https://storytale-public2.b-cdn.net/static/assets/account-app/assets/img/EmptyOrder@2x.png'}
              showButton={false}
            />
            : null
          }
        </div>
      </div>
    </div>
  )

};

export default Orders;
