import React, { useEffect, useState } from 'react';
import { Form, Button, Checkbox } from 'antd';
import { postFormData } from "../helpers/apiRequestsHelper";
import './NotificationsForm.scss';

function NotificationsForm({activeSubscriptions}) {
  const [currentAnonsState, setCurrentAnonsState] = useState();
  const [currentHeatingState, setCurrentHeatingState] = useState();
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [formControl] = Form.useForm();

  const subscribe = (formData, type) => {
    return postFormData('api/newsletter/subscribe', formData)
      .then((response) => {
        if (response.success) {
          switch (type) {
            case 'anons':
              setCurrentAnonsState(true);
              break;
            case 'heating':
              setCurrentHeatingState(true);
              break;
            default:
              break;
          }
        }
      })
      .catch((response) => {
        console.error(response);
      });
  }

  const unsubscribe = (formData, type) => {
    return postFormData('api/newsletter/unsubscribe', formData)
      .then((response) => {
        if (response.success) {
          switch (type) {
            case 'anons':
              setCurrentAnonsState(false);
              break;
            case 'heating':
              setCurrentHeatingState(false);
              break;
            default:
              break;
          }
        }
      })
      .catch((response) => {
        console.error(response);
      });
  }

  const onFinish = (values) => {
    const newAnonsState = values.announces;
    const newHeatingState = values.heating;
    const allRequests = [];

    if (newAnonsState !== currentAnonsState) {
      setButtonDisabled(true);

      const formData = new FormData();
      formData.append('newsletterType', 'anons');

      if (newAnonsState === true) {
        allRequests.push(subscribe(formData, 'anons'));
      }
      if (newAnonsState === false) {
        allRequests.push(unsubscribe(formData, 'anons'));
      }
    }

    if (newHeatingState !== currentHeatingState) {
      setButtonDisabled(true);

      const formData = new FormData();
      formData.append('newsletterType', 'heating');

      if (newHeatingState === true) {
        allRequests.push(subscribe(formData, 'heating'));
      }
      if (newHeatingState === false) {
        allRequests.push(unsubscribe(formData, 'heating'));
      }
    }

    Promise.all(allRequests)
      .finally(() => {
        setButtonDisabled(false);
    });
  };

  const setDefaultCheckboxValues = (activeSubscriptions, formControl) => {
    try {
      const anons = activeSubscriptions['anons'];
      const heating = activeSubscriptions['heating'];

      setCurrentAnonsState(anons);
      setCurrentHeatingState(heating);

      formControl.setFieldsValue({
        announces: anons || false,
        heating: heating || false,
        important: true,
      });
    } catch {
      formControl.setFieldsValue({
        announces: false,
        heating: false,
        important: true,
      });
    }
  };

  useEffect(() => {
    if (activeSubscriptions && formControl) {
      setDefaultCheckboxValues(activeSubscriptions, formControl);
    }
  }, [activeSubscriptions, formControl]);

  if (activeSubscriptions) {
    return (
      <Form
        form={formControl}
        name='NotificationsForm'
        onFinish={onFinish}
        className='notifications-form'
      >
        <Form.Item
          name='announces'
          valuePropName='checked'
        >
          <Checkbox>
            Announces and arrivals
          </Checkbox>
        </Form.Item>

        <p className='notifications-form__explanation'>
          New packs and illustrations on Storytale
        </p>

        <Form.Item
          name='heating'
          valuePropName='checked'
        >
          <Checkbox>
            Random helpful stuff
          </Checkbox>
        </Form.Item>

        <p className='notifications-form__explanation'>
          Receive marketing communications that we&nbsp;think you will&nbsp;be interested&nbsp;in.
        </p>

        <Form.Item
          name='important'
          valuePropName='checked'
        >
          <Checkbox disabled={true}>
            Account and order information
          </Checkbox>
        </Form.Item>

        <p className='notifications-form__explanation notifications-form__explanation-last'>
          Receive important information about your orders and&nbsp;account.
        </p>

        <p className='notifications-form__caption'>
          You can update these options at&nbsp;any&nbsp;time by&nbsp;clicking the&nbsp;unsubscribe link in&nbsp;the&nbsp;footer of any email you
          receive from&nbsp;us, or&nbsp;in&nbsp;your account area. By&nbsp;clicking below, you&nbsp;agree that we&nbsp;may process your information
          in&nbsp;accordance with&nbsp;our{' '}
          <a className='notifications-form__link' target='_blank' href={`${process.env.REACT_APP_STORYTALE_APP}/terms-of-use`}>
            terms&nbsp;and&nbsp;conditions
          </a>
          {' '}and{' '}
          <a className='notifications-form__link' target='_blank' href={`${process.env.REACT_APP_STORYTALE_APP}/privacy-policy`}>
            privacy&nbsp;policy
          </a>
          .
        </p>

        <Form.Item>
          <Button type='primary' htmlType='submit' className='notifications-form__button' disabled={buttonDisabled}>
            Save changes
          </Button>
        </Form.Item>
      </Form>
    );
  } else {
    return null;
  }
}

export default NotificationsForm;
