import React from "react";
import "./BrowseByIllustrations.css"

import IllustrationsList from "./IllustrationsList";
import SkeletonButton from "./SkeletonButton";
import ButtonBase from "./ButtonBase";
import Skeleton from "@material-ui/lab/Skeleton";
import goMarket from "../lib/goMarket";

function BrowseByIllustrations(props) {
  const {
    pictures,
    status,
    showTitle = true,
    containerType = "col4",
    titleText = "Browse by packs",
    buttonText = "Explore full pack",
    requestParams,
    onClick,
    callback,
    href = null,
    customerActivitys = () => {},
    downloadErrorHandler = () => {},
    getCustomerActivities,
    redirect = false,
    downloadCallback = () => {},
    likeCallback = () => {},
    picturesLikesCounts,
  } = props;

  const {page, count} = requestParams;

  return (
    <div className="browse-by-illustrations">
      {showTitle &&
      <div className="header">
        {props.status ?
          <Skeleton variant="text" animation="pulse" width="30%"/> :
          <h4 className="title text-center">{titleText}</h4>
        }
      </div>
      }
      <div className="body">
        <IllustrationsList
          pictures={pictures}
          onClick={onClick}
          status={status}
          containerType={containerType}
          customerActivitys={customerActivitys}
          downloadErrorHandler={downloadErrorHandler}
          getCustomerActivities={getCustomerActivities}
          downloadCallback={downloadCallback}
          likeCallback={likeCallback}
          picturesLikesCounts={picturesLikesCounts}
        />
      </div>
      <div className="footer">
        {status ?
          <SkeletonButton/> :
          redirect ?
            <div
              className="description"
              onClick={() => goMarket(`pack/${href}`)}
            >
              <ButtonBase
                  isEmulate={true}
                  classes={`custom mx-auto ${pictures.length !== (count * page) && 'opacity'}`}
                  content={buttonText}
                />
            </div> :
            <ButtonBase
              classes={`custom mx-auto ${pictures.length !== (count * page) && 'opacity'}`}
              content={buttonText}
              callback={callback}
            />
        }
      </div>
    </div>
  );
}

export default BrowseByIllustrations;

