import React, { useEffect } from 'react';
import { Redirect, useLocation } from "react-router-dom";

import MainTitle from '../../components/MainTitle';
import InfoField from '../../components/InfoField';
import InfoFieldWithLink from '../../components/InfoFieldWithLink';
import EmptyPageFiller from '../../components/EmptyPageFiller';

import moment from "moment";

// Redux
import { useDispatch } from 'react-redux';
import { authorizeAction } from '../../actions';

import './index.scss';

//Images
const StandartThankYou = 'https://storytale-public2.b-cdn.net/static/assets/account-app/assets/img/ThankYou.png';
const BigThankYou = 'https://storytale-public2.b-cdn.net/static/assets/account-app/assets/img/ThankYou@2x.png';

const ThankYou = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();

  let thankYouTitle = 'Thank You';
  let checkoutDetails = {};
  let orderDetails = {};
  let comleteData = null;

  useEffect(() => {
    dispatch(authorizeAction());

  }, [dispatch])


  if (location.state) {
    thankYouTitle = location.state.thankYouTitle;
    checkoutDetails = location.state.checkout;
    orderDetails = location.state.order;
    comleteData = moment(location.state.order.completed.date);
  return (
    <div className='container ThankYou'>
      <div className='row'>
        <div className='col-12 offset-0 col-md-6 col-lg-4 offset-lg-2'>
          <MainTitle text={thankYouTitle} />
          <InfoField label='Checkout Id' value={checkoutDetails.checkout_id} />
          <InfoField label='Checkout Title' value={checkoutDetails.title} />
          <InfoField label='Date' value={comleteData.isValid() ? comleteData.format("MMMM D, YYYY") : null} />
          <InfoField label='Email' value={orderDetails.customer.email} />
          <InfoField label='Total' value={`$${orderDetails.total}`} />
          <InfoField label='Tax' value={`$${orderDetails.total_tax}`} />
          <InfoFieldWithLink label='Receipt Url' link_url={orderDetails.receipt_url} />
        </div>
        <div className='col-12 col-md-6'>
          <EmptyPageFiller
            StandartImage={StandartThankYou}
            BigImage={BigThankYou}
            linkPath='browse'
          />
        </div>
      </div>
    </div>
  ) } else {
    return <Redirect to="/" />
  }
}

export default ThankYou;
