import React, { useEffect, useState, Fragment } from 'react';
import axios from "axios";
import moment from "moment";
import Skeleton from '@material-ui/lab/Skeleton';
import { v4 as uuidv4 } from 'uuid';

// Components
import BigTitle from "../../components/BigTitle.tsx";
import EmptyPageFiller from "../../components/EmptyPageFiller";
import useStateCallback from "../../lib/customHooks/useStateCallback";
import ModalIllustrationInfo from "../../components/ModalIllustrationInfo";
import changeIllustrationActivities from "../../lib/changeIllustrationActivities";
import IllustrationsList from "../../components/IllustrationsList";
import ModalSubscriptionError from "../../components/ModalSubscriptionError";
import ModalDownloadLimit from "../../components/ModalDownloadLimit";

// Common JS
import getCustomerActivities from "../../lib/getCustomerActivities";
import getIllustrationInfo from "../../lib/getIllustrationInfo";
import illustrationDownload from '../../lib/illustrationDownload.js';
import illustrationLike from "../../lib/illustrationLike";
import loadMoreIllustrationsFrom from "../../lib/loadMoreIllustrationsFrom";
import goMarket from "../../lib/goMarket";
import downloadErrorHandler from "../../lib/downloadErrorHandler";
import addPictureLikeCounts from "../../lib/addPictureLikeCounts";
import changePicturesLikesCountsLocally from "../../lib/changePicturesLikesCountsLocally";

// Redux
import { connect, useDispatch } from 'react-redux';
import { getActiveSubscriptionInfo } from "../../reducers/userSlice";

// Styles
import './index.scss';

//Images
const StandartEmptyDowload = "https://storytale-public2.b-cdn.net/static/assets/account-app/assets/img/EmptyDownload.png";
const BigEmptyDowload = "https://storytale-public2.b-cdn.net/static/assets/account-app/assets/img/EmptyDownload@2x.png";

// Constants
const MAX_ELEMENTS = 12;

// Redux mapStateToProps
const mapStateToProps = state => ({
  autoRenewal: state.userReducer.autoRenewal,
  endDate: state.userReducer.endDate,
  hasSubscribe: state.userReducer.hasSubscribe,
});

function Downloads(props) {
  const [illustrations, setIllustrations] = useStateCallback([]);
  const [picturesLikesCounts, setPicturesLikesCounts] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [modalDownloadStatus, setModalDownloadStatus] = useStateCallback(false);
  const [modalSubscriptionStatus, setModalSubscriptionStatus] = useStateCallback(false);

  const [illustrationsFromKeywordsParams, setIllustrationsFromKeywordsParams] =
    useStateCallback({
      searchText: "",
      count: MAX_ELEMENTS,
      page: 1
    });
  const [illustrationsFromPackParams, setIllustrationsFromPackParams] =
    useStateCallback({
      packId: "",
      count: MAX_ELEMENTS,
      page: 1
    });
  const [isLoadingKeywordsIllustrations, setIsLoadingKeywordsIllustrations] = useState(true);
  const [isLoadingPackIllustrations, setIsLoadingPackIllustrations] = useState(true);
  const [isLoadingInfoData, setIsLoadingInfoData] = useState(true);
  const [isLoadingPackInfo, setIsLoadingPackInfo] = useState(true);
  const [illustrationsFromKeywords, setIllustrationsFromKeywords] = useStateCallback([]);
  const [illustrationsFromPack, setIllustrationsFromPack] = useStateCallback([]);
  const [currentIllustrationInfo, setCurrentIllustrationInfo] = useStateCallback(null);
  const [showModalInfo, setShowModalInfo] = useState(false);
  const [currentIllustrationPackData, setCurrentIllustrationPackData] = useState(null);
  const [customerActivities, setCustomerActivities] = useState({});
  const [packs, setPacks] = useState([]);

  const dispatch = useDispatch();
  const downloadClick = () => dispatch(getActiveSubscriptionInfo);
  const {endDate, autoRenewal} = props;

  useEffect(() => {
    axios.get('/api/illustration/download')
      .then(response => {
        if (response.data.result)
          setIllustrations(response.data.result.downloads, (data) => getCustomerActivitiesWrapper(data));
        setIsLoading(false)
      })
      .catch(err => {
        console.log(err);
        setIsLoading(false);
      });

    axios.get(`/api/pack`)
      .then(response => {
      if (response.data.result)
        setPacks(response.data.result.packsData);
    })
      .catch(err => {
        console.log(err);
      });

  }, [dispatch, setIllustrations]);

  useEffect(() => {
    if (illustrations.length > 0) setPicturesLikesCounts(addPictureLikeCounts(picturesLikesCounts, illustrations));
  }, [illustrations]);

  useEffect(() => {
    if (illustrationsFromKeywords.length > 0) setPicturesLikesCounts(addPictureLikeCounts(picturesLikesCounts, illustrationsFromKeywords));
  }, [illustrationsFromKeywords]);

  useEffect(() => {
    if (illustrationsFromPack.length > 0) setPicturesLikesCounts(addPictureLikeCounts(picturesLikesCounts, illustrationsFromPack));
  }, [illustrationsFromPack]);

  function addDownload(id) {
    illustrationDownload(id, downloadErrorHandlerWrapper, downloadClick, changeIllustrationActivitiesWrapper);
  }

  function addLike(id) {
    illustrationLike(id, changeIllustrationActivitiesWrapper, changePicturesLikesCountsLocallyWrapper);
  }

  function getCustomerActivitiesWrapper (originalData) {
    getCustomerActivities(originalData , customerActivities, setCustomerActivities);
  }

  function changeIllustrationActivitiesWrapper (id, type, result) {
    changeIllustrationActivities(id, type, result, currentIllustrationInfo, setCurrentIllustrationInfo, customerActivities, setCustomerActivities);
  }

  function changePicturesLikesCountsLocallyWrapper(id, actionTaken) {
    changePicturesLikesCountsLocally(picturesLikesCounts, setPicturesLikesCounts, id, actionTaken);
  }

  function loadMoreIllustrationsFromWrapper () {
    loadMoreIllustrationsFrom(illustrationsFromKeywords, setIllustrationsFromKeywords, illustrationsFromKeywordsParams, setIllustrationsFromKeywordsParams, customerActivities, setCustomerActivities);
  }

  function clearStateIllustrationFromParams (id) {
    setIsLoadingKeywordsIllustrations(true);
    setIsLoadingPackIllustrations(true);
    setIsLoadingInfoData(true);
    setIsLoadingPackInfo(true);
    setIllustrationsFromKeywords([], () => {});
    setIllustrationsFromPack([], () => {});

    let illustrationsFromKeywordsParamsObj = Object.assign({}, illustrationsFromKeywordsParams);
    illustrationsFromKeywordsParamsObj.searchText = "";
    illustrationsFromKeywordsParamsObj.page = 1;

    let illustrationsFromPackParamsObj = Object.assign({}, illustrationsFromPackParams);
    illustrationsFromPackParamsObj.packId = "";
    illustrationsFromPackParamsObj.page = 1;

    setIllustrationsFromKeywordsParams(illustrationsFromKeywordsParamsObj, (data) => {
      setIllustrationsFromPackParams(illustrationsFromPackParamsObj, () => {});
      changeIllustrationInfo(id, data);
    });

  }

  function changeIllustrationInfo(id, params = illustrationsFromKeywordsParams) {
    getIllustrationInfo(
      id,
      params,
      illustrationsFromPackParams,
      setIllustrationsFromKeywordsParams,
      setIllustrationsFromPackParams,
      setIsLoadingKeywordsIllustrations,
      setIsLoadingPackIllustrations,
      setIsLoadingInfoData,
      setIsLoadingPackInfo,
      setIllustrationsFromKeywords,
      setIllustrationsFromPack,
      getCustomerActivitiesWrapper,
      setCurrentIllustrationInfo,
      setShowModalInfo,
      setCurrentIllustrationPackData
    )
  }

  function downloadErrorHandlerWrapper (errorCode, errorMessage) {
    downloadErrorHandler(errorCode, setModalSubscriptionStatus, setModalDownloadStatus, setShowModalInfo, errorMessage);
  }


  if (!isLoading)
    return (
        <Fragment>
        <div className="container-custom downloads">
          {illustrations !== null && illustrations.length
              ? <Fragment>
                <div className="row">
                  <ModalSubscriptionError
                    show={modalSubscriptionStatus}
                    handleClose={() => setModalSubscriptionStatus(false)}
                  />
                  <ModalDownloadLimit
                    show={modalDownloadStatus}
                    handleClose={() => setModalDownloadStatus(false)}
                    endDate={moment(endDate).format("MMM Do")}
                    autoRenewal={autoRenewal}
                  />
                  <ModalIllustrationInfo
                    show={showModalInfo}
                    showModalLogin={() => {}}
                    handleClose={() => {
                      setShowModalInfo(false);
                    }}
                    data={currentIllustrationInfo}
                    packData={currentIllustrationPackData}
                    packIllustrations={illustrationsFromPack}
                    keywordsIllustrations={illustrationsFromKeywords}
                    requestKeywordsParams={illustrationsFromKeywordsParams}
                    requestPackParams={illustrationsFromPackParams}
                    getCustomerActivities={customerActivities}
                    isLoading={isLoadingInfoData}
                    isLoadingPackInfo={isLoadingPackInfo}
                    isLoadingPackIllustrations={isLoadingPackIllustrations}
                    isLoadingKeywordsIllustrations={isLoadingKeywordsIllustrations}
                    callbackButtonKeywordsClick={loadMoreIllustrationsFromWrapper}
                    customerActivities={changeIllustrationActivitiesWrapper}
                    downloadErrorHandler={downloadErrorHandlerWrapper}
                    callbackTagClick={(target) => {
                      goMarket(target, true);
                    }}
                    packs={packs}
                    onClick={(id) => clearStateIllustrationFromParams(id)}
                    redirect={true}
                    downloadCallback={addDownload}
                    likeCallback={addLike}
                    picturesLikesCounts={picturesLikesCounts}
                  />
                  <div className="col-12">
                    <BigTitle text="Downloads"/>
                  </div>
                </div>
                <div className="row downloads__illustrations">
                  <div className="col-12">
                    <IllustrationsList
                      pictures={illustrations}
                      onClick={(id) => clearStateIllustrationFromParams(id)}
                      status={isLoading}
                      customerActivitys={changeIllustrationActivitiesWrapper}
                      containerType={"col4"}
                      downloadErrorHandler={() => {}}
                      getCustomerActivities={customerActivities}
                      downloadCallback={addDownload}
                      likeCallback={addLike}
                      picturesLikesCounts={picturesLikesCounts}
                    />
                  </div>
                </div>
              </Fragment>
              : <div className="row">
                <div className="col-12 offset-0 col-md-6 offset-md-3 col-lg-4 offset-lg-4 ">
                  <EmptyPageFiller
                      text="You have not downloaded illustrations yet"
                      StandartImage={StandartEmptyDowload}
                      BigImage={BigEmptyDowload}
                  />
                </div>
              </div>}
        </div>
      </Fragment>
    );
  else {
    const skeletons = [];
    return (
      <div className="container-custom downloads">
        <div className="row">
          <div className="col-12">
            <BigTitle text="Downloads"/>
          </div>
        </div>
        <div className="row downloads__illustrations">
          {(() => {
            for (let index = 0; index <= 3; index++) {
              skeletons.push(
                <div className="col-12 col-sm-6 col-lg-3" key={uuidv4()}>
                  <Skeleton variant="rect" width={"100%"} animation="wave" style={{borderRadius: "10px"}}>
                    <div style={{paddingTop: "75%"}}/>
                  </Skeleton>
                </div>)
            }
            return skeletons;
          })()}
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps)(Downloads);
