import React from "react";

import SubTitle from "../../components/SubTitle.tsx";
import Picture from "../../components/Picture";

import './index.scss';

//Images
const Standart404Image = "https://storytale-public2.b-cdn.net/static/assets/account-app/assets/img/404.png";
const Big404Image = "https://storytale-public2.b-cdn.net/static/assets/account-app/assets/img/404@2x.png";

export default function PageNotFound() {
  return (
    <div className="container-fluid PageNotFound">
      <div className="row text-center">
        <div className="col-12 offset-0 col-md-6 offset-md-3 col-lg-4 offset-lg-4">
          <Picture standart={Standart404Image} big={Big404Image} />
          <SubTitle text="Oh, no! This page does not exist." />
        </div>
      </div>
    </div>
  )
}
