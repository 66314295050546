import React from "react";

import MainTitle from '../../components/MainTitle.tsx';
import SettingsSidebar from '../../components/SettingsSidebar';
import SettingsForm from '../../components/SettingsForm';
import SubTitle from "../../components/SubTitle";
import InfoField from "../../components/InfoField";

// Redux
import { connect } from 'react-redux';

//Common js
import { settingsSidebarItems } from '../../lib/settingsSidebarItems';
// import { getHistoryPage } from '../../lib/domainPagesHistory';

import './index.scss';

// Redux mapStateToProps
const mapStateToProps = state => ({
  authorized: state.userReducer.authorized,
  userName: state.userReducer.userName,
  userEmail: state.userReducer.userEmail,
})

function Settings({authorized, userName, userEmail, ...rest }) {
  // const backLink = getHistoryPage() || null;

  if (authorized) {
    return (
      <div className='container settings'>
        <div className='row'>
          <div className='col-12 col-md-2'>
            <SettingsSidebar items={settingsSidebarItems} />
          </div>
          <div className='col-12 offset-0 col-md-9 offset-md-1'>
            <MainTitle text='Settings' />
            {(userName || userEmail) && <SubTitle text='Info' />}
            {userName && <InfoField label="Username" value={userName || ''}/>}
            {userEmail && <InfoField label="Email" value={userEmail || ''}/>}
            <SettingsForm userName={userName} />
          </div>
        </div>
      </div>
    )
  }
  return null
}

export default connect(mapStateToProps)(Settings);
