import React from "react";
import "./BrowseByPacks.css"

import PacksList from "./PacksList";
import SkeletonButton from "./SkeletonButton";
import ButtonBase from "./ButtonBase";
import goMarket from "../lib/goMarket";

function BrowseByPacks(props) {
  const {
    packs,
    status,
    showTitle = true,
    titleText = "Browse by packs",
    buttonText = "Explore all packs",
    gridSize="normal",
    onClick = () => {},
    customButtonCallback = () => {},
    customButton = false,
    skeletonCount = 17
  } = props;

  return (
    <div className="browse-by-packs">
      {showTitle &&
        <div className="header">
          <h4 className="text-center title">{titleText}</h4>
        </div>
      }
      <div className="body">
        <PacksList
          packs={packs}
          status={status}
          gridSize={gridSize}
          skeletonCount={skeletonCount}
          onClick={onClick}
        />
      </div>
      <div className="footer">
        {status ?
          <SkeletonButton/> :
          customButton ?
              <ButtonBase
                isEmulate={true}
                classes="custom mx-auto"
                content={buttonText}
                callback={customButtonCallback}
              /> :

            <div
              onClick={() => goMarket(`browse/?packs=true`)}
            >
               <ButtonBase
                 isEmulate={true}
                 classes="custom mx-auto"
                 content={buttonText}
               />
            </div>
        }
      </div>
    </div>
  );
}

export default BrowseByPacks;

