import Button from 'react-bootstrap/Button';

import SubTitle from './SubTitle.tsx';
import Picture from './Picture';
import { DottetBox } from './Icons';

import PropTypes from 'prop-types';
import './EmptyPageFiller.scss';
import goMarket from "../lib/goMarket";

const EmptyPageFiller= ({ text, StandartImage, BigImage, linkText, linkPath, buttonType ,showIcon, showButton}) => (
  <div className='EmptyPageFiller'>
    <Picture standart={StandartImage} big={BigImage} />
    { text ? <SubTitle text={text} /> : null}
    { showButton &&
      <Button variant={buttonType} onClick={() => goMarket(linkPath)}>
        {showIcon ? <DottetBox svgColor='#fff' className='btn__icon_right'/> : null}
        {linkText}
      </Button> }
  </div>
);

EmptyPageFiller.propTypes = {
  text:          PropTypes.string,
  StandartImage: PropTypes.string,
  BigImage:      PropTypes.string,
  linkText:      PropTypes.string,
  linkPath:      PropTypes.string,
  buttonType:    PropTypes.string,
  showIcon:      PropTypes.bool,
  showButton:    PropTypes.bool
};
  
EmptyPageFiller.defaultProps = {
  linkText:     'Explore catalog',
  linkPath:     'browse',
  buttonType:   'black',
  showIcon:     true,
  showButton:   true
};  

export default EmptyPageFiller;
