import React from "react";
import "./PopupElement.scss";
import goMarket from "../lib/goMarket"

function PopupElement(props) {
  const { data } = props;

  return (
    <div className="popup-element">
        <div className="popup-element__item">
          <p className="popup-element__title">Pack</p>
          {data && data.hasOwnProperty("pack") &&
          <>
            <div
              className="popup-element__description_link"
              onClick={() => goMarket(`pack/${data && data.pack.id}`)}
            >
              {data.pack.name}
            </div>
          </>
          }
        </div>

        <div className="popup-element__item">
          <p className="popup-element__title">Mood</p>
          {data && data.hasOwnProperty("mood") && data.mood !== null &&
            <div
              className="popup-element__description_link"
              onClick={() => goMarket(`browse/?moodIds[]=${data.mood.id}&count=20`)}
            >
              {data.mood.name}
            </div>
          }
        </div>

      <div className="popup-element__item">
        <p className="popup-element__title">Category</p>
        {data && data.hasOwnProperty("category") && data.category !== null &&
          <p className="popup-element__description_plain">
            {data.category.name}
          </p>
        }
      </div>

      <div className="popup-element__item">
        <p className="popup-element__title">File types</p>
        <ul className="popup-element__list">
          {data && data.hasOwnProperty("assignedExtensions") && data.assignedExtensions.map((key, index) => (
            <li key={index} className="popup-element__list-item">
              <p className="popup-element__description_plain">.{key}</p>
            </li>
          ))}
        </ul>
      </div>

    </div>
  );
}

export default PopupElement;
