import React, { FC }from "react";
import "./SubTitle.scss";

interface SubTitleProps extends React.HTMLAttributes<HTMLOrSVGElement> {
  level?: keyof JSX.IntrinsicElements;
  className?: string,
  text?: string,
}

const SubTitle: FC<SubTitleProps> = ({ level, text }) => {
  const Tag = level as keyof JSX.IntrinsicElements;
  return <Tag className="SubTitle">{text}</Tag>;
};

SubTitle.defaultProps = {
  text: "Title",
  level: "p"
}

export default SubTitle;
