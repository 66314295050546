import { Link } from 'react-router-dom';

import "./AccountSignLink.scss";

export default function AccountSignLink(props) {
  return (
    <div className="AccountSignLink">
      <p>{props.text}</p>
      <Link to={props.linkroute}>{props.linktext}</Link>
    </div>
  );
}
