import Skeleton from "@material-ui/lab/Skeleton";
import {Popover, Tooltip} from "antd";
import React from "react";
import {useSelector} from 'react-redux';

import {Download, Heart, IconInfo} from "./Icons";
import ButtonIcon from "./ButtonIcon";
import BadgeElement from "./BadgeElement";
import PopupElement from "./PopupElement";

import "./ProductInfo.css";

const placeholder = "You can download it again while you have an active subscription without spending limits.";

function Picture({ cover, modification="original", altText=''}) {
  const pictureSizes = {
    original: [410, 360, 485, 540, 645],
    inModal: [410, 600],
  };

  const originalPicture = (
    <picture>
      <source srcSet={`${cover}?height=${pictureSizes[modification][0]} 1x, ${cover}?height=${pictureSizes[modification][0] * 2} 2x`} media="(max-width: 576px)" />
      <source srcSet={`${cover}?height=${pictureSizes[modification][1]} 1x, ${cover}?height=${pictureSizes[modification][1] * 2} 2x`} media="(max-width: 768px)" />
      <source srcSet={`${cover}?height=${pictureSizes[modification][2]} 1x, ${cover}?height=${pictureSizes[modification][2] * 2} 2x`} media="(max-width: 992px)" />
      <source srcSet={`${cover}?height=${pictureSizes[modification][3]} 1x, ${cover}?height=${pictureSizes[modification][3] * 2} 2x`} media="(max-width: 1270px)" />
      <img src={`${cover}?height=${pictureSizes[modification][4]}`}  srcSet={`${cover}?height=${pictureSizes[modification][4] * 2} 2x`} alt={altText} />
    </picture>
  );

  const inModalPicture = (
    <picture>
      <source srcSet={`${cover}?height=${pictureSizes[modification][0]} 1x, ${cover}?height=${pictureSizes[modification][0] * 2} 2x`} media="(max-width: 1510px)" />
      <img src={`${cover}?height=${pictureSizes[modification][1]}`}  srcSet={`${cover}?height=${pictureSizes[modification][1] * 2} 2x`} alt={altText} />
    </picture>
  );

  return ( modification === "original" ? originalPicture : inModalPicture );
}

function ProductInfo(
  {
    data,
    isLoading,
    inModal,
    customerActivities,
    callbackTagClick,
    getCustomerActivities,
    downloadCallback = () => {},
    likeCallback = () => {},
    picturesLikesCounts,
  }) {

  const subscriptionStatus = useSelector((state) => state.userReducer.hasSubscribe);
  const authorizationStatus = useSelector((state) => state.userReducer.authorized);

  let found = data ? getCustomerActivities.hasOwnProperty(data.id) : null;
  let isDownloaded = found ? getCustomerActivities[data.id].isDownloaded : false;
  let isLiked = found ? getCustomerActivities[data.id].isLiked : false;
  const isFree = (data && data.hasOwnProperty("isFree")) ? data.isFree : false;

  const content = (
    <PopupElement
      data={data}
    />
  );

  return (
    <section className={`product-info ${inModal ? `in-modal` : ``}`}>
      <div className="widget-single-product">
        <div className="middle">
          {isLoading ? (
            <Skeleton variant="rect" width="35%" animation="wave">
              <span style={{display: 'block', paddingTop: "50px" }}/>
            </Skeleton>
          ) : (
            <h1>{data && data.hasOwnProperty("name") && data.name}</h1>
          )}
        </div>
        <div className="bottom">
          {isLoading ? (
            <Skeleton variant="rect" width="80px" animation="wave">
              <span style={{display: 'block',  paddingTop: "40px" }} />
            </Skeleton>
          ) : (
            <div className="btn-wrapper">
              <ButtonIcon
                icon={data && authorizationStatus && isLiked ? <Heart fill="#F76567"/> : <Heart />}
                content={picturesLikesCounts && picturesLikesCounts[data.id]}
                classes="neutral like"
                callback={() => likeCallback(data.id)}
              />
            </div>
          )}

          {isLoading ? (
            <Skeleton variant="rect" width="120px" animation="wave">
              <span style={{display: 'block', paddingTop: "40px" }} />
            </Skeleton>
          ) : (
            data && authorizationStatus && isDownloaded && !isFree
              ?
              <Tooltip title={placeholder} color="#9130DE" placement="bottom">
                <div className="btn-wrapper">
                  <ButtonIcon
                    icon={<Download />}
                    content={subscriptionStatus ? "Download" : "Subscribe to download"}
                    classes={authorizationStatus && isDownloaded ? "purple" : "blue"}
                    callback={() => downloadCallback(data.id)}
                  />
                </div>
              </Tooltip>
              :
              <div className="btn-wrapper">
                <ButtonIcon
                  icon={<Download />}
                  content={isFree ? "Download for free" : (subscriptionStatus ? "Download" : "Subscribe to download")}
                  classes={authorizationStatus && isDownloaded ? "purple" : "blue"}
                  callback={() => downloadCallback(data.id)}
                />
              </div>
          )}
        </div>
      </div>

      <div className="illustrations">
        <div className="product">
          <div className="product__picture">
            {isLoading !== true && inModal === true &&
              <Picture
                cover={data.cover}
                modification="inModal"
                altText={data && data.hasOwnProperty("name") && data.name}
              />
            }
            {isLoading !== true && inModal !== true &&
              <Picture
                cover={data && data.cover}
                altText={data && data.hasOwnProperty("name") && data.name}
              />
            }
          </div>
          {data &&
            <Popover
              content={content}
              trigger="click"
              placement="bottomRight"
              getPopupContainer={trigger => trigger.parentElement}
            >
              <button className="info-button">
                <IconInfo fill="#7C7C7C"/>
              </button>
            </Popover>
          }
        </div>
      </div>

      {(data && data.hasOwnProperty("description"))
        ?
        (data.description.length > 0 &&
          <p className="product-info__description">
            {data.description}
          </p>
        )
        :
        null
      }

      <div className="product-info__keywords">
        {isLoading ?
          <Skeleton variant="rect" width="100%" animation="wave" style={{marginTop: "10px"}}>
            <span style={{display: 'block', paddingTop: "22px"}}/>
          </Skeleton>
          :
          (data && data.hasOwnProperty("keywords")
              ? data.keywords.map((key, index) => (
                <BadgeElement
                  key={index}
                  classes="outline neutral"
                  content={key}
                  onClick={() => callbackTagClick(key)}
                />
              ))
              : null
          )
        }
      </div>
    </section>
  );
}

export default ProductInfo;
