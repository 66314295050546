import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import { v4 as uuidv4 } from 'uuid';

// Pages
import PageNotFound from './pages/404';
import Registration from './pages/registration';
import Login from './pages/login';
import LogOut from './pages/logout';
import RestorePassword from './pages/restorepassword';
import ResetPassword from './pages/resetpassword';
import GoogleLogin from './pages/googlelogin';
import Downloads from './pages/downloads';
import Likes from './pages/likes';
import Subscriptions from './pages/subscriptions';
import SubscriptionSingle from './pages/subscriptions/SubscriptionSingle';
import Checkout from './pages/checkout';
import ThankYou from './pages/thankyou';
import Settings from './pages/settings';
import EmailConfirmation from './pages/emailconfirmation';
import Orders from './pages/order';
import Order from './pages/order/OrderSingle';
import Notifications from './pages/notifications';
import ErrorBoundary from "./components/ErrorBoundary";

import { getCookie } from "./lib/cookies";

export const ROUTES = [
  { path: "/",
    key: "ROOT",
    exact: true,
    component: () => {
      return <Redirect to={"/settings"} />
    }
  },
  { path: "/downloads",
    key: "DOWNLOADS",
    exact: true,
    component: () => {
      if (getCookie('sessionUuid')) return <ErrorBoundary><Downloads /></ErrorBoundary>;
      else return <Redirect to={"/login"} />
    },
  },
  { path: "/likes",
    key: "LIKES",
    exact: true,
    component: () => {
      if (getCookie('sessionUuid')) return <ErrorBoundary><Likes /></ErrorBoundary>;
      else return <Redirect to={"/login"} />
    },
  },
  // Login paths >>
  { path: "/register",
    key: "REGISTER",
    exact: true,
    component: () => <ErrorBoundary><Registration /></ErrorBoundary>,
  },
  { path: "/login",
    key: "LOGIN",
    exact: true,
    component: () => <ErrorBoundary><Login /></ErrorBoundary>,
  },
  { path: "/logout",
    key: "LOGOUT",
    exact: true,
    component: () => <ErrorBoundary><LogOut /></ErrorBoundary>,
  },
  { path: "/googlelogin",
    key: "GOOGLELOGIN",
    exact: true,
    component: () => <ErrorBoundary><GoogleLogin /></ErrorBoundary>,
  },
  { path: "/restore-password",
    key: "RESTOREPASSWORD",
    exact: true,
    component: () => <ErrorBoundary><RestorePassword /></ErrorBoundary>,
  },
  { path: "/reset-password",
    key: "RESETPASSWORD",
    exact: true,
    component: () => <ErrorBoundary><ResetPassword /></ErrorBoundary>,
  },
  {
    path: "/emailconfirmation",
    key: "EMAILCONFIRMATION",
    exact: true,
    component:  () => <ErrorBoundary><EmailConfirmation /></ErrorBoundary>,
  },
  // Login paths <<
  { path: "/checkout/:id(\\d+)",
    key: uuidv4(),
    exact: true,
    component: () => <ErrorBoundary><Checkout /></ErrorBoundary>,
  },
  {
    path: "/thankyou",
    key: "THAKYOU",
    exact: true,
    component: () => <ErrorBoundary><ThankYou /></ErrorBoundary>,

  },
  // Settings paths
  { path: "/settings",
    key: "SETTINGS",
    exact: true,
    component: () => {
      if (getCookie('sessionUuid')) return <ErrorBoundary><Settings /></ErrorBoundary>;
      else return <Redirect to={"/login"} />
    },
  },
  { path: "/subscription",
    key: "SUBSCRIPTION",
    exact: true,
    component: () => {
      if (getCookie('sessionUuid')) return <ErrorBoundary><Subscriptions /></ErrorBoundary>;
      else return <Redirect to={"/login"} />
    },
  },
  { path: "/subscription/:id(\\d+)",
    key: uuidv4(),
    exact: true,
    component: () => {
      if (getCookie('sessionUuid')) return <ErrorBoundary><SubscriptionSingle /></ErrorBoundary>;
      else return <Redirect to={"/login"} />
    },
  },
  { path: "/orders",
    key: "ORDERS",
    exact: true,
    component: () => {
      if (getCookie('sessionUuid')) return <ErrorBoundary><Orders /></ErrorBoundary>;
      else return <Redirect to={"/login"} />
    },
  },
  { path: "/order/:id(\\d+)",
    key: "ORDER",
    exact: true,
    component: () => {
      if (getCookie('sessionUuid')) return <ErrorBoundary><Order /></ErrorBoundary>;
      else return <Redirect to={"/login"} />
    },
  },
  { path: "/notifications",
    key: "NOTIFICATIONS",
    exact: true,
    component: () => {
      if (getCookie('sessionUuid')) return <ErrorBoundary><Notifications /></ErrorBoundary>;
      else return <Redirect to={"/login"} />
    },
  },
];

function RouteWithSubRoutes(route) {
  return (
    <Route
      path={route.path}
      exact={route.exact}
      render={props => <route.component {...props} routes={route.routes} />}
    />
  );
}

export function RenderRoutes({ routes }) {
  return (
    <Switch>
      {routes.map((route, i) => {
        return <RouteWithSubRoutes key={route.key} {...route}/>;
      })}
      <Route path="/404" component={() => <ErrorBoundary><PageNotFound/></ErrorBoundary>} />
      <Redirect to="/404" />
    </Switch>
  );
}
