import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import MainTitle from '../../components/MainTitle.tsx';
import SettingsSidebar from '../../components/SettingsSidebar';
import NotificationsForm from '../../components/NotificationsForm';
import {getRequest} from '../../helpers/apiRequestsHelper'
import {settingsSidebarItems} from '../../lib/settingsSidebarItems';
import './index.scss';

// Redux mapStateToProps
const mapStateToProps = state => ({
  authorized: state.userReducer.authorized,
  userEmail: state.userReducer.userEmail,
})

const expectedTypes = ['anons', 'heating'];
const defaultSubscriptions = {'anons': false, 'heating': false};

function Notifications({authorized, userEmail, ...rest }) {
  const [activeSubscriptions, setActiveSubscriptions] = useState(null);


  const filterSubscriptionsFromServer = (data, expectedTypes) => {
    try {
      if (data.success) {
        const subscriptionsArray = data.result.newsletterSubscription;
        const subscriptions = {};

        // construct object like this: {'anons': false, 'heating': false} from server data array
        for (let i = 0; i < subscriptionsArray.length; i++) {
          const subscriptionType = subscriptionsArray[i]['type'];
          const subscriptionStatus = subscriptionsArray[i]['isActive'];
          subscriptions[subscriptionType] = subscriptionStatus;
        };

        // delete unexpected types of newsletter subscriptions from object, filter by keys
        const filteredSubscriptions = Object.keys(subscriptions)
          .filter(key => expectedTypes.includes(key))
          .reduce((obj, key) => {
            return {
              ...obj,
              [key]: subscriptions[key]
            };
          }, {});

        // add default (false) value for types of newsletter, that we haven't got from server (expectedTypes)
        for (let i = 0; i < expectedTypes.length; i++) {
          const type = expectedTypes[i];
          if (!filteredSubscriptions.hasOwnProperty(type)) {
            filteredSubscriptions[type] = false;
          }
        }
        return filteredSubscriptions;
      } else {
        return defaultSubscriptions;
      }
    }
    catch {
      console.error('something is wrong with data from server');
      return defaultSubscriptions;
    }
  };

  const getNewsletterSubscriptionsList = () => {
    getRequest(`/api/newsletter/getList`)
      .then((data) => {
        const subscriptions = filterSubscriptionsFromServer(data, expectedTypes);
        setActiveSubscriptions(subscriptions);
      })
      .catch((err) => {
        console.error(err);
        setActiveSubscriptions(defaultSubscriptions);
      });
  };

  useEffect(() => {
    if (authorized) {
      getNewsletterSubscriptionsList();
    }
  }, [authorized])

  return (
    <div className='container notifications'>
      <div className='row'>
        <div className='col-12 col-md-2'>
          <SettingsSidebar items={settingsSidebarItems} />
        </div>
        <div className='col-12 offset-0 col-md-9 offset-md-1'>
          <MainTitle text='Notifications' className='notifications__title'/>
          {authorized && <>
            <p className='notifications__caption'>
              You are managing preferences for
              <span className='notifications__email'>
                &nbsp;{userEmail || ''}
              </span>
            </p>
            <NotificationsForm activeSubscriptions={activeSubscriptions}/>
            </>
          }
        </div>
      </div>
    </div>
  );
}

export default connect(mapStateToProps)(Notifications);

