import React, { Component } from 'react';
import { ROUTES, RenderRoutes } from "./routes";
import { Link } from "react-router-dom";
import { Fragment } from 'react';

import Header from './components/Header';
import ErrorBoundary from "./components/ErrorBoundary";

// Redux
import { connect } from 'react-redux';
import { checkAuthorization, getActiveSubscriptionInfo } from "./reducers/userSlice";
import {fetchMoodsData} from "./reducers/browseSlice";

import './App.scss';
import Footer from "./components/Footer";

/*
 * mapDispatchToProps
*/
const mapDispatchToProps = dispatch => ({
  authorizeAction: () => dispatch(checkAuthorization),
  fetchMoodsData: () => dispatch(fetchMoodsData),
  getActiveSubscriptionInfo: () => dispatch(getActiveSubscriptionInfo),
});

/*
 * mapStateToProps
*/
const mapStateToProps = state => ({ ...state });

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      menuIsOpen: false,
      menuDisplay: "block",
    }
  }

  componentDidMount() {
    window.$crisp=[];
    window.CRISP_WEBSITE_ID=process.env.REACT_APP_CRISP_WEBSITE_ID;
    (function(){
      var d=document;
      var s=d.createElement("script");
      s.src="https://client.crisp.chat/l.js";
      s.async=1;
      d.getElementsByTagName("head")[0].appendChild(s);
    })();

    this.props.authorizeAction();
    this.props.fetchMoodsData();
    this.props.getActiveSubscriptionInfo();
  }

  render() {
    return (
      <Fragment>
        <ErrorBoundary noDisplay={true}>
          <Header />
        </ErrorBoundary>
        <div className="App">
          <div className="App__sidebar">
            {displayRouteMenu(ROUTES)}
          </div>
          <div className="App__content">
            <RenderRoutes routes={ROUTES} />
          </div>
        </div>
        <ErrorBoundary noDisplay={true}>
          <Footer bordered={true}/>
        </ErrorBoundary>
      </Fragment>
    );
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(App);

function displayRouteMenu(routes) {
  function singleRoute(route) {
    return (
      <li key={route.key}>
        <Link to={route.path}>
          {route.key} ({route.path})
        </Link>
      </li>
    );
  }

  return (
    <ul>
      {routes.map(route => {
        if (route.routes) {
          return (
            <React.Fragment key={route.key}>
              {singleRoute(route)}
              {displayRouteMenu(route.routes)}
            </React.Fragment>
          );
        }

        return singleRoute(route);
      })}
    </ul>
  );
}
