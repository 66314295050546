import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import MainTitle from '../../components/MainTitle.tsx';
import {useDispatch} from 'react-redux';

import { Table } from 'antd';
import Button from "react-bootstrap/Button";

import { v4 as uuidv4 } from 'uuid';

// Common scripts
import useStateCallback from '../../lib/customHooks/useStateCallback';
import { postFormData, getRequest } from '../../helpers/apiRequestsHelper';
import { openNotificationWithIcon } from '../../lib/notifications';

import './index.scss';
import '../../common_styles/tableWrapper.scss';
import '../../common_styles/styledAntPopover.scss';
import '../../common_styles/buttons.scss';
import {getActiveSubscriptionInfo} from "../../reducers/userSlice";

const checkoutOrderColumns = [
  {
    title: 'Price',
    dataIndex: 'price',
    key: 'price'
  },
  {
    title: 'Plan',
    dataIndex: 'name',
    key: 'name'
  }
];

const totalPriceColumns = [
  {
    title: 'Total price',
    dataIndex: 'totalPrice',
    key: 'totalPrice'
  }
];

function Checkout(props) {
  const { id } = useParams();
  const history = useHistory();
  const Paddle = (window && window.hasOwnProperty('Paddle')) ? window.Paddle: undefined;
  const paddle_props = ['Environment', 'Checkout', 'Order'];
  const is_paddle = Paddle && paddle_props.every(prop => Paddle.hasOwnProperty(prop));
  let openCheckout = undefined;
  const dispatch = useDispatch();

  const [checkoutOrderData, setCheckoutOrderData] = useStateCallback([]);
  const [checkoutTotalPrice, setCheckoutTotalPrice] = useState([]);
  const [orderStatus, setOrderStatus] = useState(null);
  const [paddleId, setPaddleId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const is_sandbox = (process.env.REACT_APP_PADDLE_IS_SANDBOX === 'true');

  if (is_paddle) {
    if (is_sandbox) {
      Paddle.Environment.set('sandbox');
    }

    Paddle.Setup({
      vendor: parseInt(process.env.REACT_APP_PADDLE_VENDOR_ID),
    });

    openCheckout = (id) => {
      let formData = new FormData();
      formData.append('orderId', id);
      postFormData('/api/order/confirm', formData)
          .catch(err => {
            console.log(err);
          });

      Paddle.Checkout.open({
        product: paddleId,
        passthrough: `{\"orderId\": ${id}}`,
        successCallback: checkoutComplete,
        closeCallback: checkoutClosed,
      });
    }
  }

  function checkoutComplete(data) {
    let checkoutId = data.checkout.id;

    Paddle.Order.details(checkoutId, function(data) {
      try {
        data.thankYouTitle = 'Order received';
        dispatch(getActiveSubscriptionInfo);
        history.push({
          pathname: '/thankyou',
          state: data,
        });
      } catch (error) {
        openNotificationWithIcon('error', 'Error', 'Something gone wrong');
      }
    });
  }

  function checkoutClosed() {
    history.push(`/order/${id}`);
  }

  useEffect(() => {
    function fetchOrderDetails(id) {
      const getParams = { params: {orderId: id}};
      getRequest('/api/order/one', getParams)
        .then(data => {
          if (data.success) {
            data.result.order.orderPositions.map(order => {
              order.product.key = uuidv4();
              order.product.price = `$ ${order.product.price}`;
              order.product.name = order.product.name.charAt(0).toUpperCase() + order.product.name.slice(1);
              return true;
            });
            setOrderStatus(data.result.order.status);
            setCheckoutTotalPrice(data.result.order.totalPrice);
            setCheckoutOrderData([data.result.order.orderPositions[0].product],
              () => {
                setPaddleId(data.result.order.orderPositions[0].product.paddleId);
                setIsLoading(false);
              });
          } else {
            setIsLoading(false);
          }
        })
        .catch(err => {
          setIsLoading(false);
          console.error(err);
        });
    }
    fetchOrderDetails(id);
  }, [id, setCheckoutOrderData]);

  function paddleUnreachableHandling(description = 'Some trouble with payment system') {
    openNotificationWithIcon('error', 'Error', description);
  }

  if (!isLoading) {
    if (orderStatus !== 3) {
      return (
        <div className='container checkout'>
          <div className='row'>
            <div className='col-12 offset-md-2 col-md-8 offset-lg-3 col-lg-6 checkout__header'>
              <MainTitle text='Checkout'/>
            </div>
            <div className='col-12 offset-md-2 col-md-8 offset-lg-3 col-lg-6'>
              <div className='tableWrapper checkout__order'>
                <Table
                  className='settingsTable_subs'
                  columns={checkoutOrderColumns}
                  dataSource={checkoutOrderData}
                  pagination={false}
                />
              </div>
              <div className='tableWrapper checkout__order'>
                <Table
                  className='settingsTable_subs_one_column'
                  columns={totalPriceColumns}
                  dataSource={[{totalPrice: `$ ${checkoutTotalPrice}`, key: uuidv4()}]}
                  pagination={false}
                />
              </div>
              <Button variant='secondary' onClick={
                () => openCheckout ? openCheckout(id) : paddleUnreachableHandling('Paddle is unreachable now, try later')
              }>
                Pay {`$${checkoutTotalPrice}`}
              </Button>
              <p>VAT may be charged following your country law.</p>
              <p>Your personal data will be used to process your order, support your experience throughout this website, and for other purposes described in our <a className='text-link_black' href={`${process.env.REACT_APP_STORYTALE_APP}/privacy-policy`}>privacy policy</a>.</p>
            </div>
          </div>
        </div>
      )
    }
    return history.goBack();
  }
  return null;
}

export default Checkout;
