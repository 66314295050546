import React, {useEffect, useState} from "react";
import Button from "react-bootstrap/Button";
import { Info, ArrowDown, RoundedSlash } from "./Icons";
import { Tooltip } from 'antd';
import generateTimePeriodCaption from '../lib/generateTimePeriodCaption';

import PropTypes from "prop-types";

import "./SubscriptionPlanCard.scss";

const SubscriptionPlanCard = ({
      id,
      name,
      durationCount,
      duration,
      price,
      status, //status can be "current", "best", "other"
      buttonCaption,
      downloadLimit,
      handleClick,
      tooltipText,
      buttonDisabled,
      chargePeriodCount,
      chargePeriodLabel,
}) => {
  const [pricePerDownload, setPricePerDownload] = useState(0);
  const [durationLabel, setDurationLabel] = useState('');
  const [chargeLabel, setChargeLabel] = useState('');
  const generateLimitDurationLabel = (durationCount, duration) => {
    const article = (durationCount === 1 ? 'a ' : 'per ');
    const caption = generateTimePeriodCaption(durationCount, duration);
    return article + caption;
  };

  useEffect(() => {
    if ((price > 0) && (downloadLimit > 0) && (chargePeriodCount > 0) && (durationCount > 0)) {
      setPricePerDownload(price / ( downloadLimit * ( chargePeriodCount / durationCount )));
    }
  }, [price, downloadLimit, chargePeriodCount, durationCount]);

  useEffect(() => {
    if ((durationCount > 0) && (duration.length > 0)) {
      setDurationLabel(generateLimitDurationLabel(durationCount, duration));
    }
  }, [durationCount, duration]);

  useEffect(() => {
    if ((chargePeriodCount > 0) && (chargePeriodLabel.length > 0)) {
      setChargeLabel(generateTimePeriodCaption(chargePeriodCount, chargePeriodLabel));
    }
  }, [chargePeriodCount, chargePeriodLabel]);

  const buttonStyles = {
      current: 'btn-primary',
      other: 'btn-outline-primary',
      best: 'btn-secondary'
  };

  return (
      <div className="SubscriptionPlanCard">
          <div className={(status === 'current' || status === 'current-old')? "SubscriptionPlanCard__header_show" : "SubscriptionPlanCard__header_hide"}>
              <p className="SubscriptionPlanCard__header-title">Current plan</p>
              <Tooltip color="#000" placement="rightTop" title="Your current plan is available on a renewal basis only. If you cancel it or switch to another one you’re no more able to return here.">
                <div className="SubscriptionPlanCard__header-wrap-info">
                    <Info color="white" />
                </div>
              </Tooltip>
          </div>
          <div className={`SubscriptionPlanCard__body_${status}`}>
              <div className="SubscriptionPlanCard__plan-info">
                  <p className="SubscriptionPlanCard__name">{name || ''}</p>
                  {downloadLimit && <p className="SubscriptionPlanCard__downloads">{`${downloadLimit} downloads ${durationLabel}`}</p>}
              </div>
              <div className="SubscriptionPlanCard__plan-price">
                  <p className="SubscriptionPlanCard__price">${price || ''}</p>
                  <RoundedSlash color="black" />
                  <p className="SubscriptionPlanCard__duration">{chargeLabel}</p>
              </div>
              {pricePerDownload && <div className="SubscriptionPlanCard__per-piece">
                  <ArrowDown color="black"/>
                  <p className="SubscriptionPlanCard__per-piece-price">${(pricePerDownload).toFixed(2)} for download</p>
              </div>}

          </div>
          <div className={`SubscriptionPlanCard__button-container_${status}`}>
              <Tooltip color="#000" placement="bottom" title={tooltipText || ''}>
                <Button disabled={buttonDisabled} className={`${buttonStyles[status]}`} onClick={handleClick}>{buttonCaption}</Button>
              </Tooltip>
          </div>
      </div>)
};

SubscriptionPlanCard.propTypes = {
  id:              PropTypes.number.isRequired,
  name:            PropTypes.string,
  price:           PropTypes.number,
  duration:        PropTypes.string,
  durationCount:   PropTypes.number,
  status:          PropTypes.string,
  downloadLimit:   PropTypes.number,
  buttonCaption:   PropTypes.string,
  handleClick:     PropTypes.func,
  tooltipText:     PropTypes.string,
  buttonDisabled:  PropTypes.bool,
  chargePeriodCount: PropTypes.number,
  chargePeriodLabel: PropTypes.string,
};

SubscriptionPlanCard.defaultProps = {
  buttonCaption: "",
  buttonDisabled: false,
  tooltipText: "",
  handleClick: () => {},
  duration: '',
  durationCount: 0,
  chargePeriodCount: 0,
  chargePeriodLabel: '',
};

export default SubscriptionPlanCard;
