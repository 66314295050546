const generateTimePeriodCaption = (count, timeUnit) => {
  let caption = '';
  if (timeUnit === 'day') {
    if ((count % 7 === 0) && (count > 7)) {
      caption = `${count / 7} weeks`;
      return caption;
    }
    switch (count) {
      case 1:
        caption = 'day';
        break;
      case 7:
        caption = 'week';
        break;
      default:
        caption = `${count} days`;
        break;
    }
  }
  if (timeUnit === 'month') {
    if ((count % 12 === 0) && (count > 12)) {
      caption = `${count / 12} years`;
      return caption;
    }
    switch (count) {
      case 1:
        caption = 'month';
        break;
      case 3:
        caption = 'quarter';
        break;
      case 6:
        caption = 'half-year';
        break;
      case 12:
        caption = 'year';
        break;
      default:
        caption = `${count} months`;
        break;
    }
  }
  return caption;
};

export default generateTimePeriodCaption;
