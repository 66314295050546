import { useEffect } from 'react';

import MainTitle from '../../components/MainTitle';
import RestorePasswordForm from '../../components/RestorePasswordForm';
import Picture from '../../components/Picture';

// Redux
import { useDispatch } from 'react-redux';
import { authorizeAction } from '../../actions';

import './index.scss';

//Images
const LostPasswordStd = "https://storytale-public2.b-cdn.net/static/assets/account-app/assets/img/LostPassword.png";
const LostPasswordBig = "https://storytale-public2.b-cdn.net/static/assets/account-app/assets/img/LostPassword@2x.png";

function RestorePassword(props) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(authorizeAction())
  }, [dispatch])

  return (
    <div className='container restore-password'>
      <div className='row'>
        <div className='col-12 offset-0 col-md-4 offset-md-1 d-flex flex-column justify-content-center'>
          <MainTitle text='Lost password' />
          <RestorePasswordForm />
        </div>
        <div className='col-12 offset-0 col-md-5 offset-md-1 mt-5 mt-md-0'>
          <Picture standart={LostPasswordStd} big={LostPasswordBig} />
        </div>
      </div>
    </div>
  )
}

export default RestorePassword;
