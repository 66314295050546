
const goMarket = (url, linkToBrowse = false) => {
  if (linkToBrowse) {
    window.location.href = `${process.env.REACT_APP_STORYTALE_APP}/browse/?searchText=${url}`;
  }
  else {
    window.location.href = `${process.env.REACT_APP_STORYTALE_APP}/${url}`;
  }
}

export default goMarket;
