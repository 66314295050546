import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';
import {StorytaleLogo} from './Icons';
import goMarket from "../lib/goMarket";

class Footer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      count: '3446',
    }
  }

  render() {

    const {count} = this.state;
    const { bordered } = this.props;
    const date = new Date();

    return (
      <footer className={`site-footer ${bordered === true ? `bordered` : null}`}>
        <div className="container-custom">
          <div className="row">
            <div className="col">
              <div className="wrapper">
                <div className="first">
                  <Link className="link" to="/" onClick={() => goMarket('')}>
                    <StorytaleLogo/>
                  </Link>
                  <p className="footer-text">{count} High-quality design illustrations to bring life into you projects</p>
                </div>
                <div className="second"><p className="header">Explore</p>
                  <ul className="list">
                    <li>
                      <div className="link" onClick={() => goMarket(`browse/`)}>Catalog</div>
                    </li>
                    <li>
                      <div className="link" onClick={() => goMarket(`plans`)}>Pricing</div>
                    </li>
                  </ul>
                </div>
                <div className="third">
                  <p className="header">Legals</p>
                  <ul className="list">
                    <li>
                      <div className="link" onClick={() => goMarket(`privacy-policy`)}>Privacy</div>
                    </li>
                    <li>
                      <div className="link" onClick={() => goMarket(`license-agreement`)}>Licenses</div>
                    </li>
                    <li>
                      <div className="link" onClick={() => goMarket(`terms-of-use`)}>Terms</div>
                    </li>
                    <li>
                      <div className="link" onClick={() => goMarket(`refund-policy`)}>Refunds</div>
                    </li>
                    <li>
                      <div className="link" onClick={() => goMarket(`cookies-policy`)}>Cookies</div>
                    </li>
                  </ul>
                </div>
                <div className="fourth">
                  <p className="header">Company</p>
                  <ul className="list">
                    <li>
                      <div className="link" onClick={() => goMarket(`order-custom`)}>Custom work</div>
                    </li>
                    <li>
                      <div className="link" onClick={() => goMarket(`about-us`)}>About</div>
                    </li>
                    <li>
                      <div className="link" onClick={() => goMarket(`contact-us`)}>Contacts</div>
                    </li>
                    <li>
                      <div className="link" onClick={() => goMarket(`help`)}>Help</div>
                    </li>
                  </ul>
                </div>
                <div className="fifth">
                  <div className="left">
                    <p className="bottom-text">Created on sunshine coast by <a href="https://craftwork.design/" target="_blank">Craftwork ©</a> 2020 - {date.getFullYear()}</p>
                  </div>
                  <div className="right">
                    <ul className="socials">
                    <li>
                        <a href="https://dribbble.com/storytale" target="_blank" rel="noreferrer" className="bottom-text bottom-link">Dribbble</a>
                      </li>
                      <li>
                        <a href="https://www.instagram.com/storytale.io" target="_blank" rel="noreferrer" className="bottom-text bottom-link">Instagram</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
