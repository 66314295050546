import React from "react";
import "./PacksList.css";
import WidgetPack from "./WidgetPack";
import SkeletonPack from "./SkeletonPack";
import {LazyLoadComponent} from "react-lazy-load-image-component";


function packsList(props) {
  const {packs, onClick, status, gridSize, skeletonCount=14} = props;

  function renderCards (count) {
    const items = [];

    for (let x = 0; x <= count; x++) {
      items.push(
          <SkeletonPack key={x}/>
          )
    }

    return (
        <>
          {items}
        </>
    );
  }

  return (
    <div className={`wrapper packs-list ${gridSize ? `packs-list-${gridSize}` : ''}`}>
      {status === true && renderCards(skeletonCount)}

      {packs !== null && status !== true
          ? packs.map((pack, index) => (
          <LazyLoadComponent threshold={300} key={index}>
              <WidgetPack
                  id={pack.id}
                  key={pack.id}
                  name={pack.name}
                  cover={pack.cover}
                  quantity={pack.illustrationCount}
                  onClick={onClick}
              />
          </LazyLoadComponent>
          ))
          : null}
  </div>
  );
}

export default packsList;
