import redirectToMarket from "./redirectToMarket";
import {openNotificationWithIcon} from "./notifications";

const downloadErrorHandler = (errorCode, showModalSubscriptionEnded, showModalDownloadLimits, hideModalInfo, errorMessage) => {

  switch (errorCode) {
    case 109001002:
      showModalSubscriptionEnded(true);
      break;
    case 109001001:
      redirectToMarket('plans');
      break;
    case 109001003:
      showModalDownloadLimits(true);
      hideModalInfo(false);
      break;
    default:
      openNotificationWithIcon('error', 'Error', errorMessage);
      return ;
  }
}

export default downloadErrorHandler;
