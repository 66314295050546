import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";


import MainTitle from "../../components/MainTitle.tsx";
import PictureRandomizer from '../../components/PictureRandomizer';

import { parseQuery } from '../../lib/search.js';

import axios from "axios";

// Redux
import { useDispatch } from 'react-redux';
import {checkAuthorization, getActiveSubscriptionInfo} from '../../reducers/userSlice';

import './index.scss';
import {eraseCookie, getCookie, setCookie} from "../../lib/cookies";

function GoogleLogin(props) {
  const [searchQueryExist, setSearchQueryExist] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();

  async function getGoogleResponse(url, req) {
    try {
        let res =  await axios.post(url, req);
        return res.data
    }
    catch (err) {
        console.error(err);
    }
  }

  function googleApiAuthorize() {
    if (location.search.length > 0) {
      let formData = new FormData();
      let searchQuery = parseQuery(location.search);

      if (searchQuery.code) {
        Object.entries(searchQuery).forEach(entry => {
          const [key, value] = entry;
          formData.append(key, value);
        });
      }

      getGoogleResponse(`/api/remoteAuth/googleAuthorize`, formData)
        .then(res => {
          dispatch(checkAuthorization);
          dispatch(getActiveSubscriptionInfo);
          setCookie('dropUser', 'false', 100, process.env.REACT_APP_BACKLINK_COOCKIE_DOMAIN);
          eraseCookie('dropUser');
          const cookieArray = getCookie('pageFrom').split('>>');
          const lastCookie = cookieArray[cookieArray.length - 1];
          window.location.href = lastCookie;
        })
        .catch(err=> { console.log(err) });

      setSearchQueryExist(true);
    } else {
      setSearchQueryExist(false);
    }
  }

  useEffect(() => {
    googleApiAuthorize();
  }, []);

  if (searchQueryExist !== 'true') {

    return null
  } else return (
    <div className="container googleLogin">
      <div className="row">
        <div className="col-12 col-md-4">
          <MainTitle text="Google log in" />
        </div>
        <div className="col-12 offset-0 offset-md-1 col-md-7">
          <PictureRandomizer min={1} max={8} />
        </div>
      </div>
    </div>
  )
}

export default GoogleLogin;
