import React, { FC }  from "react";
import "./MainTitle.scss";

interface MainTitleProps extends React.HTMLAttributes<HTMLOrSVGElement> {
  level?: keyof JSX.IntrinsicElements;
  className?: string,
  text?: string,
}

const MainTitle: FC<MainTitleProps> = ({ level, text }) => {
  const Tag = level as keyof JSX.IntrinsicElements;
  return <Tag className="MainTitle">{text}</Tag>;
};

MainTitle.defaultProps = {
  text: "Title",
  level: "h1"
}

export default MainTitle;
