import React, { useState, useEffect } from 'react';
import "./PictureRandomizer.scss";

import Picture from './Picture';

const images = [
  "https://storytale-public2.b-cdn.net/static/assets/account-app/assets/img/SampleImages/Image1@2x.png",
  "https://storytale-public2.b-cdn.net/static/assets/account-app/assets/img/SampleImages/Image2@2x.png",
  "https://storytale-public2.b-cdn.net/static/assets/account-app/assets/img/SampleImages/Image3@2x.png",
  "https://storytale-public2.b-cdn.net/static/assets/account-app/assets/img/SampleImages/Image4@2x.png",
  "https://storytale-public2.b-cdn.net/static/assets/account-app/assets/img/SampleImages/Image5@2x.png",
  "https://storytale-public2.b-cdn.net/static/assets/account-app/assets/img/SampleImages/Image6@2x.png",
  "https://storytale-public2.b-cdn.net/static/assets/account-app/assets/img/SampleImages/Image7@2x.png",
  "https://storytale-public2.b-cdn.net/static/assets/account-app/assets/img/SampleImages/Image8@2x.png"
]

export default function PictureRandomizer (props) {
  const [number, setNumber] = useState(1);

  const generateNumber = (min, max) => {
    return Math.floor(min + Math.random() * (max + 1 - min) - 1);
  }

  useEffect(() => {
    setNumber(generateNumber(props.min, props.max));
  }, [props.min, props.max]);

  return (
    <div className="PictureRandomizer">
      <Picture standart={images[number]} big={images[number]} />
    </div>  
  );
}
