import React from "react";
import Modal from "react-bootstrap/Modal";
import '../common_styles/buttons.scss'
import "./Modal.scss"
import ProductInfo from "./ProductInfo";
import {CloseElement, Escape} from "./Icons";
import Skeleton from "@material-ui/lab/Skeleton";
import {Avatar} from "antd";
import ButtonIcon from "./ButtonIcon";
import BrowseByPacks from "./BrowseByPacks";
import BrowseByIllustrations from "./BrowseByIllustrations";
import goMarket from "../lib/goMarket";

const MAX_PACKS = 15;

const ModalIllustrationInfo = (
  {
    show,
    handleClose,
    isLoading,
    isLoadingPackInfo,
    isLoadingPackIllustrations,
    isLoadingKeywordsIllustrations,
    data,
    packData,
    status,
    hasSubscription,
    showModalLogin,
    requestKeywordsParams,
    requestPackParams,
    customerActivities,
    callbackButtonKeywordsClick = () => {},
    callbackButtonPackClick = () => {},
    callbackPackClick = () => {},
    customButtonCallback = () => {},
    downloadErrorHandler,
    callbackTagClick,
    packs,
    packIllustrations,
    keywordsIllustrations,
    getCustomerActivities,
    onClick,
    customButtonHeader = false,
    redirect = false,
    browseByPacksCustomButton = false,
    skeletonCount = 14,
    downloadCallback = () => {},
    likeCallback = () => {},
    picturesLikesCounts,
}) => {


  const scrollToTop = () => {
    let anchorElement = document.getElementsByClassName('target')[0];
    if(anchorElement !== null) {
      anchorElement.scrollTo(0, 0);
    }
  };

  const packsData = packs.filter(
    (item, index) => index < MAX_PACKS
  );

    return (
        <Modal
          animation={true}
          show={show}
          className="target"
          onHide={handleClose}
          dialogClassName="modal-dialog modal-dialog-illustration-info  modal-dialog-centered"
        >
          <Modal.Header>

            {isLoadingPackInfo ?
              <Skeleton variant="rect" animation="wave" height={50} width={140} style={{position : 'absolute'}}/>
              :
              customButtonHeader ?
                <ButtonIcon
                  isEmulate={true}
                  callback={() => {
                    callbackPackClick(packData.id)
                  }}
                  classes="large neutral outline escape"
                  icon={
                    <Escape/>
                  }
                  content={
                    <>
                      <Avatar size={32} shape="circle" src={packData && packData.cover}/>
                      {packData && packData.name}
                    </>
                  }
                /> :
                <div
                  onClick={() => goMarket(`pack/${packData && packData.id}`)}
                >
                  <ButtonIcon
                    isEmulate={true}
                    classes="large neutral outline escape"
                    icon={
                      <Escape/>
                    }
                    content={
                      <>
                        <Avatar size={32} shape="circle" src={packData && packData.cover}/>
                        {packData && packData.name}
                      </>
                    }
                  />
                </div>
            }

            <span className="close-button" onClick={handleClose}>
               <CloseElement/>
            </span>
          </Modal.Header>

          <Modal.Body>
            <ProductInfo
              data={data}
              isLoading={isLoading}
              hasSubscription={hasSubscription}
              inModal={true}
              status={status}
              showModalLogin={showModalLogin}
              customerActivities={customerActivities}
              callbackTagClick={callbackTagClick}
              downloadErrorHandler={downloadErrorHandler}
              getCustomerActivities={getCustomerActivities}
              downloadCallback={downloadCallback}
              likeCallback={likeCallback}
              picturesLikesCounts={picturesLikesCounts}
            />

            {keywordsIllustrations.length > 0 &&
              <section className="section">
                <BrowseByIllustrations
                  titleText={`You might also like`}
                  buttonText={`Load more illustrations`}
                  pictures={keywordsIllustrations}
                  requestParams={requestKeywordsParams}
                  onClick={(id) => {
                    onClick(id);
                    scrollToTop();
                  }}
                  callback={callbackButtonKeywordsClick}
                  status={isLoadingKeywordsIllustrations}
                  containerType="col3"
                  customerActivitys={customerActivities}
                  downloadErrorHandler={downloadErrorHandler}
                  getCustomerActivities={getCustomerActivities}
                  downloadCallback={downloadCallback}
                  likeCallback={likeCallback}
                  picturesLikesCounts={picturesLikesCounts}
                />
              </section>
            }

            {packIllustrations.length > 0 &&
              <section className="section">
                <BrowseByIllustrations
                  titleText={`More from ${packData && packData.name}`}
                  href={packData && packData.id}
                  pictures={packIllustrations}
                  requestParams={requestPackParams}
                  onClick={(id) => {
                    onClick(id);
                    scrollToTop();
                  }}
                  callback={() => {
                    callbackButtonPackClick(packData.id)
                  }}
                  redirect={redirect}
                  status={isLoadingPackIllustrations}
                  containerType="col3"
                  customerActivitys={customerActivities}
                  downloadErrorHandler={downloadErrorHandler}
                  getCustomerActivities={getCustomerActivities}
                  downloadCallback={downloadCallback}
                  likeCallback={likeCallback}
                  picturesLikesCounts={picturesLikesCounts}
                />
              </section>
            }

            <section className="section">
              <BrowseByPacks
                onClick={callbackPackClick}
                titleText={`Similar packs`}
                packs={packsData}
                status={isLoading}
                gridSize="small"
                customButton={browseByPacksCustomButton}
                customButtonCallback={callbackButtonPackClick}
                skeletonCount = {skeletonCount}
              />
            </section>
          </Modal.Body>
        </Modal>
    )
};
export default ModalIllustrationInfo;
