import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux'
import { Tooltip } from 'antd';
import { DownloadCloud, Heart, ArrowDownSmall } from "./Icons";
import './Product.css';

const placeholder = "You can download it again while you have an active subscription without spending limits.";

function Picture({ cover, containerType, altText}) {
    const pictureSizes = {
        col4: [410, 180, 245, 155, 205, 250],
        col3: [410, 180, 245, 220, 275, 350],
        col4Custom: [410, 180, 180, 180, 165, 210],
        col5: [410, 180, 180, 180, 165, 270],
        col2: [410, 180, 245, 340, 430, 535],
    };

  return (
      <picture>
          <source srcSet={`${cover}?height=${pictureSizes[containerType][0]} 1x, ${cover}?height=${pictureSizes[containerType][0] * 2} 2x`} media="(max-width: 576px)" />
          <source srcSet={`${cover}?height=${pictureSizes[containerType][1]} 1x, ${cover}?height=${pictureSizes[containerType][1] * 2} 2x`} media="(max-width: 768px)" />
          <source srcSet={`${cover}?height=${pictureSizes[containerType][2]} 1x, ${cover}?height=${pictureSizes[containerType][2] * 2} 2x`} media="(max-width: 992px)" />
          <source srcSet={`${cover}?height=${pictureSizes[containerType][3]} 1x, ${cover}?height=${pictureSizes[containerType][3] * 2} 2x`} media="(max-width: 1270px)" />
          <source srcSet={`${cover}?height=${pictureSizes[containerType][4]} 1x, ${cover}?height=${pictureSizes[containerType][4] * 2} 2x`} media="(max-width: 1510px)" />
          <img src={`${cover}?height=${pictureSizes[containerType][5]}`}  srcSet={`${cover}?height=${pictureSizes[containerType][5] * 2} 2x`} alt={altText} />
      </picture>
  );
}

function Product(
  {
    assetName,
    packName,
    id,
    downloadCallback,
    likeCallback,
    getCustomerActivities = null,
    cover,
    containerType,
    onClick,
    likeCount,
    isFree = false,
  }) {
  const authorizationStatus = useSelector((state) => state.userReducer.authorized);
  const [isDownloaded, setIsDownloaded] = useState(null);
  const [isLiked, setIsLiked] = useState(null);

  const onDownload = () => {
    downloadCallback(id);
  };
  const onLike = () => {
    likeCallback(id);
  };

  useEffect(() => {
    if (getCustomerActivities && getCustomerActivities.hasOwnProperty(id)) {
      setIsDownloaded(getCustomerActivities[id].isDownloaded);
      setIsLiked(getCustomerActivities[id].isLiked);
    }
  }, [getCustomerActivities]);

  return (
    <div className="product">
      <div className="product__picture" onClick={() => onClick(id)}>
        <Picture
          cover={cover}
          containerType={containerType}
          altText={assetName || ''}
        />
      </div>

      <div className="product__shade product__shade_gray" />
      <div className="product__shade product__shade_white" />

      { isFree && <div className="product__free"> <ArrowDownSmall />Free</div>}

      <div className="product__info">
        <div className="product__texts">
          <p className="product__name">
            {assetName ? assetName : ''}
          </p>
          <p className="product__pack-name">
            {packName ? packName : ''}
          </p>
        </div>

        <div className="btn-wrapper">
          <button onClick={onLike} className="dialog-link dialog-link-like">
            {authorizationStatus && isLiked ? <Heart fill="#FE3434"/> : <Heart fill="#A9A9A9"/>}
            {(Number.isInteger(likeCount) && likeCount > 0) && <p className="product__likes-count">{likeCount}</p>}
          </button>
          {authorizationStatus && isDownloaded && !isFree
            ?
            <Tooltip title={placeholder} color="#9130DE">
              <button onClick={onDownload} className="dialog-link dialog-link-download">
                <DownloadCloud fill="#9130DE"/>
              </button>
            </Tooltip>
            :
            <button onClick={onDownload} className="dialog-link dialog-link-download">
              <DownloadCloud fill={authorizationStatus && isDownloaded ? "#9130DE" : "#A9A9A9"}/>
            </button>
          }
        </div>
      </div>

    </div>
  );
}

export default Product;
