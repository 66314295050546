import React from "react";
import { NavLink } from "react-router-dom";

import { v4 as uuidv4 } from 'uuid';

import "./SettingsSidebar.scss";

export default function SettingsSidebar({ items }) {
  return (
    <ul className="SettingsSidebar">
      {items !== null 
      ? items.map(item => (
        <li key={uuidv4()} className="SettingsSidebar__item">
           <NavLink activeClassName="SettingsSidebar__link_active" className="SettingsSidebar__link" to={item.path}>
            {item.name}
           </NavLink> 
        </li>
      ))
      : null}
    </ul>
  );
}
