import React from "react";

export default function Picture(props) {
  return (
    <picture>
      <source srcSet={`${props.standart} 1x, ${props.big} 2x`}/>
      <img src={props.standart} alt={props.alt}/>
    </picture>
  );
}
