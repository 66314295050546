import React, { useEffect, useState } from 'react';
import { Redirect, useLocation } from "react-router-dom";

import queryString from 'query-string';

import emailConfirmHelper from '../../helpers/emailConfirmationHelper';

const EmailConfirmation = (props) => {
  const location = useLocation();
  var parsedSearchQuery = queryString.parse(location.search);
  var { activationUuid } = parsedSearchQuery;

  const [emailConfirmed, setEmailConfirmed] = useState( { isLoading: true, success:false, message: 'Email confirm error' } );

  useEffect(() => {
    emailConfirmHelper(activationUuid, setEmailConfirmed) },
  [setEmailConfirmed, activationUuid]);

  if (emailConfirmed.isLoading) {
    return null;
  }

  if (!emailConfirmed.isLoading && emailConfirmed.success) {
    window.location.href = `${process.env.REACT_APP_STORYTALE_APP}?emailConfrim=true`;
    return null;
  } else { return ( <Redirect to='404' /> )}

}

export default EmailConfirmation;
