import { useEffect, useState, Fragment } from 'react';
import { Link } from "react-router-dom";
import { useParams } from "react-router";

import MainTitle from '../../components/MainTitle.tsx';
import SubTitle from '../../components/SubTitle.tsx';
import SettingsSidebar from '../../components/SettingsSidebar';
import { BackwardSvg } from '../../components/Icons';
import Button from 'react-bootstrap/Button';
import InfoField from '../../components/InfoField';
import InfoFieldWithLink from '../../components/InfoFieldWithLink';

import { Table } from 'antd';

import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

//Common js
import { settingsSidebarItems } from '../../lib/settingsSidebarItems';
import { getRequest } from '../../helpers/apiRequestsHelper';
import getOrderStatusName from '../../lib/orderStatusIdNameCompare';

import './OrderSingle.scss';

const paymentTableColumns = [
  {
    title: 'Product',
    dataIndex: 'name', 
    key: 'name'
  },
  {
    title: 'Price',
    dataIndex: 'price', 
    key: 'price'
  },
];  

function Order(props) {
  const { id } = useParams();
  const [orderDetails, setOrderDetails] = useState(null);
  const [orderPositions, setOrderPostions] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchOrderDetails = (id) => {
      const getParams = { params: {orderId: id}};
      getRequest('/api/order/one', getParams)
        .then(data => {
          if (data.success) {
            data.result.order.createdDate.date = moment(data.result.order.createdDate.date);
            data.result.order.orderPositions.map(position => {
              position.key = uuidv4();
              position.price = `$ ${position.product.price}`
              position.name = position.product.name.charAt(0).toUpperCase() + position.product.name.slice(1)
              return true;
            });
            setOrderPostions(data.result.order.orderPositions);
            setOrderDetails(data.result.order);
            setIsLoading(false);
          } else {
            setIsLoading(false);
          }
        })
        .catch(err => { 
          setIsLoading(false);
        })
    }

    fetchOrderDetails(id);
  }, [id])

  return (
    <div className='container order'>
      <div className='row'>
        <div className='col-12 col-md-2'>
          <SettingsSidebar items={settingsSidebarItems} />
        </div>
        <div className='col-12 offset-0 col-md-9 offset-md-1'>
          <Link to='/Orders'>
            <Button variant='outline_light' className='order__backward-button'>
              <BackwardSvg className='btn__icon_right' />
              Orders
            </Button>
          </Link>
          { !isLoading  
            ? orderDetails !== null             
              ?  <Fragment>
                  <MainTitle text={(orderDetails.id) ? `Order # ${orderDetails.id}` : 'Order'}/>
                  <p>Order <b>#{orderDetails.id}</b> was placed on <b>{orderDetails.createdDate.date.format("MMMM D, YYYY")}</b> and is currently <b>{getOrderStatusName(orderDetails.status)}</b>.</p>
                  <SubTitle text='Payment'/>
                  <Table 
                    className='settingsTable_subs_styled-first'
                    columns={paymentTableColumns} 
                    dataSource={orderPositions} 
                    pagination={false}
                  />
                  <SubTitle text='Status'/>
                  <div className='order__twocolls'>
                    <InfoField key={uuidv4()} 
                      label="Status"
                      value={getOrderStatusName(orderDetails.status)}
                    />
                    {orderDetails.status !== 3 && <InfoFieldWithLink label='Go to checkout' link_url={`/checkout/${id}`} />}
                  </div>
                </Fragment> 
            : <Fragment>
                <MainTitle text={`Order not found`}/>
                <SubTitle text={`No order with id # ${id}`}/>
              </Fragment>
            : null
          }
        </div>
      </div>    
    </div>
  )  
}

export default Order;
