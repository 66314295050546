import { Link } from 'react-router-dom';

import PropTypes from "prop-types";

import "./LinksBlock.scss";

const LinksBlock =({
  firstLink, 
  secondLink,
  firstText,
  secondText
}) => {
  return (
    <div className="LinksBlock">
      <Link to={firstLink}>{firstText}</Link>
      <Link to={secondLink}>{secondText}</Link>
    </div>
  );
}

LinksBlock.propTypes = {
  firstLink: PropTypes.string.isRequired,
  firstText: PropTypes.string.isRequired,
  secondLink: PropTypes.string.isRequired,
  secondText: PropTypes.string.isRequired,
};

export default LinksBlock;
