import React from "react";
import "./SkeletonButton.css";
import Skeleton from "@material-ui/lab/Skeleton";

function SkeletonButton(props) {

    return (
        <section className="skeleton-button">
            <Skeleton variant="rect" animation="pulse" />
        </section>
    );
}

export default SkeletonButton;
