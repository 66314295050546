export default async function emailConfirmHelper(uuid, setState) {
  try {
    const response = await fetch(`/api/user/activate?activationUuid=${uuid}`);
    if (response.status === 200) {
      const json = await response.json();
    
      if (json.success) {
        setState(prevState => ({ ...prevState, isLoading: false, success: json.success, message: 'Email confirmed'}));
      } else {
        setState(prevState => ({ ...prevState, isLoading: false, success: json.success, message: json.message}));
      }
    } else {
      setState(prevState => ({ ...prevState, isLoading: false, success: false, message: 'Something went wrong try again later'}));
    }
  } catch (e) {
    setState(prevState => ({ ...prevState, isLoading: false, success: false, message: 'Something went wrong try again later'}));
  }
};
