import React from "react";

//styles
import "./WidgetPack.css"
import goMarket from "../lib/goMarket";

function WidgetPack(props) {
  const {type = "normal", id, cover, name, quantity, description, onClick = () => {}} = props;

  return (
    <div
      className={`widget-pack ${type}`}
      onClick={() => onClick(id)}
    >
      <div
        className={`poster ${type === `small` && `small`} ${type === `full` && `small`}`}
      >
        <div
          onClick={() => goMarket(`pack/${id}`)}
          className="image-link"
        >
          <img src={cover} alt="" />
        </div>
      </div>

      <div
        onClick={() => goMarket(`pack/${id}`)}
        className="text-link"
      >
        {type === `full` ?
          <h1 className="title">
            {name}
          </h1>
          :
          <h5 className="title">
            {name}
          </h5>
        }
      </div>

      {(type === "full" && description) &&
        <p className="descr">
          {description}
        </p>
      }

      <p className={`descr ${type === 'full' && 'text-dark'}`} >
        {quantity} illustrations
      </p>
    </div>
  );
}

export default WidgetPack;
