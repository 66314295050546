import axios from "axios";

const illustrationLike = (id, changeIllustrationActivitiesWrapper, changePicturesLikesCountsLocallyWrapper, errorCallback = () => {}) => {
  axios.post(`/api/illustration/like`, {
    illustrationId: id,
  })
    .then((response) => {
      const {action} =  response.data.result;
      changeIllustrationActivitiesWrapper(id, "isLiked", action);
      changePicturesLikesCountsLocallyWrapper(id, action);
    })
    .catch((error) => {
      error.request.status === 401 && errorCallback();
    });
};

export default illustrationLike;
