import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import MainTitle from '../../components/MainTitle';
import ResetPasswordForm from '../../components/ResetPasswordForm';
import Picture from '../../components/Picture';

import queryString from 'query-string';

// Redux
import { useDispatch } from 'react-redux';
import { authorizeAction } from '../../actions';

import './index.scss';

//Images
const LostPasswordStd = "https://storytale-public2.b-cdn.net/static/assets/account-app/assets/img/LostPassword.png";
const LostPasswordBig = "https://storytale-public2.b-cdn.net/static/assets/account-app/assets/img/LostPassword@2x.png";

function ResetPassword(props) {
  const location = useLocation();
  var parsedSearchQuery = queryString.parse(location.search);
  var { resetPasswordRequestUuid } = parsedSearchQuery;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(authorizeAction())
  }, [dispatch])

  return (
    <div className='container reset-password'>
      <div className='row'>
        <div className='col-12 offset-0 col-md-4 offset-md-1 d-flex flex-column justify-content-center'>
          <MainTitle text='Reset password' />
          <ResetPasswordForm uuid={resetPasswordRequestUuid} />
        </div>
        <div className='col-12 offset-0 col-md-5 offset-md-1 mt-5 mt-md-0'>
          <Picture standart={LostPasswordStd} big={LostPasswordBig} />
        </div>
      </div>
    </div>
  )
}

export default ResetPassword;
