import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import 'antd/dist/antd.css';
import './AccountForm.scss';

import { Form, Input, Button } from 'antd';
import axios from "axios";
import ErrorField from '../components/ErrorField';
import SuccessField from './SuccessField';

export default function ResetPasswordFrom ({uuid}) {
  const [passwordChanged, setPasswordChanged] = useState(false);
  const [backError, setBackError] = useState({
    message: 'Unknown Error',
    show: false,
  });
  const [backSuccess, setBackSuccess] = useState({
    message: 'Success',
    show: false,
  });

  const onFinish = (values) => {
    let formData = new FormData();

    formData.append('resetPasswordRequestUuid', uuid);
    formData.append('newPassword', values.password);

    axios.post(`api/user/finishResetPassword`, formData)
      .then(response => {
        if (response.data.success === true) {
          setBackSuccess({
            message: 'Password was changed',
            show: true,
          });
          setBackError({
            message: null,
            show: false,
          });
          setPasswordChanged(true);
        } else {
          setBackError({
            message: response.data.message,
            show: true,
          });
          setBackSuccess({
            message: null,
            show: false,
          });
        }
      })
      .catch(function (error) {
        setBackError({
          message: error.message,
          show: true,
        });
        setBackSuccess({
          message: null,
          show: false,
        });
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Form
      name="ResetPasswordForm"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      className="AccountForm"
    >

      <Form.Item label="New password"
        name="password"
        rules={[{
          required: true,
          message: 'Please input your password!',
        }]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item
        name="confirm"
        label="Confirm new password"
        dependencies={['password']}
        hasFeedback
        rules={[
          {
            required: true,
            message: 'Please confirm your password!',
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('The two passwords that you entered do not match!'));
            },
          }),
        ]}
      >
        <Input.Password />
      </Form.Item>

      { backError.show === true &&
        <ErrorField error_message={backError.message} show={backError.show}/>
      }   

      { backSuccess.show === true &&
        <SuccessField message={backSuccess.message} show={backSuccess.show}/>
      }

      <Form.Item>
        <Button type="primary" htmlType="submit">
          Сhange password
        </Button>
      </Form.Item>

      { passwordChanged
        ? <Link className='AccountForm__link' to='/login'>Login</Link>
        : null }
    </Form>
  );
};
