import PropTypes from "prop-types";

const iconsDefaultColor = "#7c7c7c";
const iconsPropTypes = { svgColor: PropTypes.string, className: PropTypes.string };
const iconsDefaultProps = { svgColor: iconsDefaultColor, className: "" };

export const BackwardSvg = ({svgColor, className}) => (
  <svg className={className} width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.0801 9.79806C15.0801 9.03741 14.9524 8.34325 14.6971 7.71557C14.4471 7.08258 14.0774 6.53736 13.588 6.0799C13.104 5.62245 12.5082 5.26872 11.8008 5.01871C11.0933 4.76871 10.2848 4.64371 9.37518 4.64371H4.01338L1.53992 4.75541L1.68354 5.12244L3.65433 3.4389L5.96022 1.18885C6.01873 1.12502 6.06395 1.05321 6.09586 0.973423C6.1331 0.893634 6.15171 0.803207 6.15171 0.702141C6.15171 0.49469 6.08522 0.327134 5.95224 0.199472C5.82458 0.0664907 5.65436 0 5.44159 0C5.24478 0 5.07457 0.0744695 4.93095 0.223409L0.239366 4.82722C0.159578 4.90169 0.0984062 4.98414 0.0558521 5.07457C0.0186174 5.16499 0 5.26074 0 5.36181C0 5.45755 0.0186174 5.55064 0.0558521 5.64107C0.0984062 5.73149 0.159578 5.81394 0.239366 5.88841L4.93095 10.4922C5.07457 10.6412 5.24478 10.7156 5.44159 10.7156C5.65436 10.7156 5.82458 10.6518 5.95224 10.5241C6.08522 10.3912 6.15171 10.2209 6.15171 10.0135C6.15171 9.91243 6.1331 9.822 6.09586 9.74221C6.06395 9.66242 6.01873 9.59061 5.96022 9.52678L3.65433 7.28472L1.68354 5.59319L1.53992 5.96022L4.01338 6.07193H9.49486C10.4045 6.07193 11.1678 6.22618 11.7848 6.5347C12.4018 6.84322 12.8673 7.27408 13.1811 7.82728C13.4949 8.37516 13.6519 9.00549 13.6519 9.71827C13.6519 10.4364 13.4949 11.0773 13.1811 11.6412C12.8673 12.205 12.4018 12.6492 11.7848 12.9737C11.1678 13.3034 10.4045 13.4683 9.49486 13.4683H7.65972C7.44163 13.4683 7.2661 13.5375 7.13312 13.6758C7.00014 13.8141 6.93364 13.9817 6.93364 14.1785C6.93364 14.3753 7.00014 14.5428 7.13312 14.6811C7.2661 14.8194 7.44163 14.8886 7.65972 14.8886H9.50284C10.6944 14.8886 11.705 14.6758 12.5348 14.2503C13.3646 13.8301 13.995 13.237 14.4258 12.471C14.862 11.7103 15.0801 10.8194 15.0801 9.79806Z" fill={svgColor}/>
  </svg>
);

BackwardSvg.propTypes = iconsPropTypes;
BackwardSvg.defaultProps = iconsDefaultProps;

export const DottetBox = ({svgColor, className}) => (
  <svg className={className} width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M6 1C6 0.447715 6.44772 0 7 0H8C8.55229 0 9 0.447715 9 1V2C9 2.55228 8.55228 3 8 3H7C6.44772 3 6 2.55228 6 2V1ZM12 1C12 0.447715 12.4477 0 13 0H14C14.5523 0 15 0.447715 15 1V2C15 2.55228 14.5523 3 14 3H13C12.4477 3 12 2.55228 12 2V1ZM1 6C0.447715 6 0 6.44772 0 7V8C0 8.55228 0.447715 9 1 9H2C2.55228 9 3 8.55229 3 8V7C3 6.44772 2.55228 6 2 6H1ZM6 7C6 6.44772 6.44772 6 7 6H8C8.55229 6 9 6.44772 9 7V8C9 8.55229 8.55228 9 8 9H7C6.44772 9 6 8.55228 6 8V7ZM13 6C12.4477 6 12 6.44772 12 7V8C12 8.55228 12.4477 9 13 9H14C14.5523 9 15 8.55229 15 8V7C15 6.44772 14.5523 6 14 6H13ZM0 13C0 12.4477 0.447715 12 1 12H2C2.55228 12 3 12.4477 3 13V14C3 14.5523 2.55228 15 2 15H1C0.447715 15 0 14.5523 0 14V13ZM7 12C6.44772 12 6 12.4477 6 13V14C6 14.5523 6.44772 15 7 15H8C8.55228 15 9 14.5523 9 14V13C9 12.4477 8.55229 12 8 12H7ZM12 13C12 12.4477 12.4477 12 13 12H14C14.5523 12 15 12.4477 15 13V14C15 14.5523 14.5523 15 14 15H13C12.4477 15 12 14.5523 12 14V13ZM1 0C0.447715 0 0 0.447715 0 1V2C0 2.55228 0.447715 3 1 3H2C2.55228 3 3 2.55228 3 2V1C3 0.447715 2.55228 0 2 0H1Z" fill={svgColor}/>
  </svg>
);

DottetBox.propTypes = iconsPropTypes;
DottetBox.defaultProps = iconsDefaultProps;

export const CheckMark = ({svgColor, className}) => (
  <svg className={className} width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.7104 1.20986C14.6175 1.11613 14.5069 1.04174 14.385 0.990969C14.2632 0.940201 14.1324 0.914062 14.0004 0.914062C13.8684 0.914062 13.7377 0.940201 13.6159 0.990969C13.494 1.04174 13.3834 1.11613 13.2904 1.20986L5.84044 8.66986L2.71044 5.52986C2.61392 5.43662 2.49998 5.36331 2.37512 5.3141C2.25026 5.2649 2.11694 5.24077 1.98276 5.24309C1.84858 5.24541 1.71617 5.27414 1.59309 5.32763C1.47001 5.38113 1.35868 5.45834 1.26544 5.55486C1.1722 5.65138 1.09889 5.76532 1.04968 5.89018C1.00048 6.01503 0.976347 6.14836 0.978669 6.28254C0.98099 6.41672 1.00972 6.54913 1.06321 6.67221C1.1167 6.79529 1.19392 6.90662 1.29044 6.99986L5.13044 10.8399C5.2234 10.9336 5.334 11.008 5.45586 11.0588C5.57772 11.1095 5.70843 11.1357 5.84044 11.1357C5.97245 11.1357 6.10316 11.1095 6.22502 11.0588C6.34687 11.008 6.45748 10.9336 6.55044 10.8399L14.7104 2.67986C14.8119 2.58622 14.893 2.47257 14.9484 2.34607C15.0038 2.21957 15.0324 2.08296 15.0324 1.94486C15.0324 1.80676 15.0038 1.67015 14.9484 1.54365C14.893 1.41715 14.8119 1.3035 14.7104 1.20986Z" fill={svgColor}/>
  </svg>
);

CheckMark.propTypes = iconsPropTypes;
CheckMark.defaultProps = iconsDefaultProps;

export function Download(props) {
  const fill = props.fill || "#ffffff";
  const width = props.width || "24";
  const height = props.height || "24";

  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M4.75 16.5C5.16421 16.5 5.5 16.8358 5.5 17.25V19.25C5.5 19.3881 5.61193 19.5 5.75 19.5H17.75C17.8881 19.5 18 19.3881 18 19.25V17.25C18 16.8358 18.3358 16.5 18.75 16.5C19.1642 16.5 19.5 16.8358 19.5 17.25V19.25C19.5 20.2165 18.7165 21 17.75 21H5.75C4.7835 21 4 20.2165 4 19.25V17.25C4 16.8358 4.33579 16.5 4.75 16.5Z" fill="white"/>
      <path d="M10.4879 4C9.97356 4 9.54447 4.39312 9.49955 4.90555C9.34591 6.65826 9.31881 8.41959 9.41838 10.1758C9.17139 10.1895 8.9245 10.2053 8.67773 10.2233L7.18822 10.3319C6.61392 10.3737 6.29288 11.0141 6.60293 11.4993C7.66497 13.1614 9.03343 14.6063 10.6354 15.7571L11.2321 16.1858C11.5416 16.4081 11.9585 16.4081 12.268 16.1858L12.8647 15.7571C14.4667 14.6063 15.8351 13.1614 16.8972 11.4993C17.2072 11.0141 16.8862 10.3737 16.3119 10.3319L14.8224 10.2233C14.5756 10.2053 14.3287 10.1895 14.0817 10.1758C14.1813 8.41959 14.1542 6.65826 14.0006 4.90554C13.9556 4.39312 13.5265 4 13.0122 4H10.4879Z" fill={fill}/>
    </svg>

  )
}

export function DownloadCloud (props) {
  const fill = props.fill || "#ffffff";
  const width = props.width || "24";
  const height = props.height || "24";

  return (
    <svg width={width} height={height} viewBox="0 0 22 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M17.7375 5.53667C17.1142 2.37417 14.3367 0 11 0C8.35083 0 6.05 1.50333 4.90417 3.70333C2.145 3.99667 0 6.33417 0 9.16667C0 12.2008 2.46583 14.6667 5.5 14.6667H17.4167C19.9467 14.6667 22 12.6133 22 10.0833C22 7.66333 20.1208 5.70167 17.7375 5.53667ZM15.5823 8.25002L11.3198 12.5125C11.1365 12.6958 10.8523 12.6958 10.669 12.5125L6.41564 8.25002H9.16564V4.58335H12.8323V8.25002H15.5823Z" fill={fill}/>
    </svg>
  )
}

export function Escape(props) {
  const fill = props.fill || "#000000";
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.54 14.3537C19.54 13.5931 19.4124 12.8989 19.1571 12.2712C18.907 11.6382 18.5374 11.093 18.048 10.6356C17.5639 10.1781 16.9682 9.82438 16.2607 9.57438C15.5533 9.32437 14.7447 9.19937 13.8351 9.19937H8.47334L5.99988 9.31108L6.1435 9.6781L8.11429 7.99456L10.4202 5.74452C10.4787 5.68069 10.5239 5.60888 10.5558 5.52909C10.5931 5.4493 10.6117 5.35887 10.6117 5.25781C10.6117 5.05035 10.5452 4.8828 10.4122 4.75514C10.2845 4.62215 10.1143 4.55566 9.90156 4.55566C9.70474 4.55566 9.53453 4.63013 9.39091 4.77907L4.69933 9.38288C4.61954 9.45735 4.55837 9.5398 4.51581 9.63023C4.47858 9.72066 4.45996 9.8164 4.45996 9.91747C4.45996 10.0132 4.47858 10.1063 4.51581 10.1967C4.55837 10.2872 4.61954 10.3696 4.69933 10.4441L9.39091 15.0479C9.53453 15.1968 9.70474 15.2713 9.90156 15.2713C10.1143 15.2713 10.2845 15.2075 10.4122 15.0798C10.5452 14.9468 10.6117 14.7766 10.6117 14.5692C10.6117 14.4681 10.5931 14.3777 10.5558 14.2979C10.5239 14.2181 10.4787 14.1463 10.4202 14.0824L8.11429 11.8404L6.1435 10.1489L5.99988 10.5159L8.47334 10.6276H13.9548C14.8644 10.6276 15.6277 10.7818 16.2448 11.0904C16.8618 11.3989 17.3272 11.8297 17.6411 12.3829C17.9549 12.9308 18.1118 13.5612 18.1118 14.2739C18.1118 14.992 17.9549 15.633 17.6411 16.1968C17.3272 16.7607 16.8618 17.2048 16.2448 17.5293C15.6277 17.8591 14.8644 18.024 13.9548 18.024H12.1197C11.9016 18.024 11.7261 18.0932 11.5931 18.2315C11.4601 18.3698 11.3936 18.5373 11.3936 18.7341C11.3936 18.9309 11.4601 19.0985 11.5931 19.2368C11.7261 19.3751 11.9016 19.4442 12.1197 19.4442H13.9628C15.1543 19.4442 16.165 19.2315 16.9948 18.8059C17.8246 18.3857 18.4549 17.7926 18.8858 17.0266C19.322 16.266 19.54 15.375 19.54 14.3537Z" fill={fill}/>
    </svg>
  )
}

export function Heart(props) {
  const fill = props.fill || "#000000";
  const width = props.width || "24";
  const height = props.height || "24";
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.15 5C5.36914 5 3 7.0793 3 9.7798C3 11.6427 3.87235 13.2112 5.02849 14.5104C6.18066 15.8052 7.66714 16.892 9.01097 17.8016L11.3296 19.3711C11.5835 19.543 11.9165 19.543 12.1704 19.3711L14.489 17.8016C15.8329 16.892 17.3193 15.8052 18.4715 14.5104C19.6277 13.2112 20.5 11.6427 20.5 9.7798C20.5 7.0793 18.1309 5 15.35 5C13.9165 5 12.6552 5.67214 11.75 6.54183C10.8448 5.67214 9.58347 5 8.15 5Z" fill={fill}/>
    </svg>

  )
}

export function StorytaleLogo() {
    return (
      <svg width="148" height="40" viewBox="0 0 148 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M58.496 17.792L61.232 16.6639C60.368 14.384 57.536 13.448 55.712 13.664C52.976 14 51.416 15.9439 51.416 17.7199C51.416 19.1119 52.736 20.384 54.2 20.84C55.424 21.224 58.448 21.32 58.304 22.712C58.208 23.6 56.744 23.8879 55.784 23.672C54.824 23.4559 54.08 22.688 53.792 21.92L50.96 23.144C51.56 24.656 53.168 26.504 56.36 26.504C59.84 26.504 61.472 24.392 61.472 22.784C61.472 21.2 60.68 19.6399 58.088 19.016C55.592 18.4159 54.512 18.2959 54.512 17.408C54.512 16.784 55.448 16.4479 56.264 16.4479C56.912 16.4479 58.112 16.808 58.496 17.792Z" fill="black"/>
        <path d="M70.7795 25.76L69.8915 23.024C69.3875 23.336 68.3555 23.5279 67.8035 22.9039C67.4675 22.5199 67.4195 22.184 67.4195 20.528V17.024H70.2515V14.2639H67.4435V10.7119H64.3955V13.472C64.3955 14 64.2275 14.2639 63.4835 14.2639H62.1875V17.024H64.2515V22.5199C64.2515 23.8879 65.0435 25.784 67.2035 26.216C68.9795 26.576 69.8435 26.12 70.7795 25.76Z" fill="black"/>
        <path d="M70.789 20.12C70.789 23.576 73.525 26.528 77.101 26.528C80.653 26.528 83.389 23.576 83.389 20.12C83.389 16.6639 80.653 13.8559 77.101 13.8559C73.525 13.8559 70.789 16.6639 70.789 20.12ZM73.957 20.1439C73.957 18.1279 75.493 16.7599 77.125 16.7599C78.757 16.7599 80.269 18.1279 80.269 20.1439C80.269 22.3039 78.757 23.576 77.125 23.576C75.493 23.576 73.957 22.3039 73.957 20.1439Z" fill="black"/>
        <path d="M87.9603 26.096L84.8403 26.072V14.3359H87.9603V16.0879C88.3443 15.1519 88.9203 14.576 90.0963 14.1439C90.9603 13.8319 92.4243 13.904 93.2403 14.3839L92.3763 17.24C91.4163 16.784 89.8323 16.7119 88.9203 17.7439C88.1043 18.6799 87.9603 19.3759 87.9603 22.4959V26.096Z" fill="black"/>
        <path d="M95.4158 31.424H98.8718L106.288 14.2639H102.832L99.8798 21.584L96.6878 14.2639L93.2318 14.24L98.1518 25.5919L95.4158 31.424Z" fill="black"/>
        <path d="M115.359 25.76L114.471 23.024C113.967 23.336 112.935 23.5279 112.383 22.9039C112.047 22.5199 111.999 22.184 111.999 20.528V17.024H114.831V14.2639H112.023V10.7119H108.975V13.472C108.975 14 108.807 14.2639 108.063 14.2639H106.767V17.024H108.831V22.5199C108.831 23.8879 109.623 25.784 111.783 26.216C113.559 26.576 114.423 26.12 115.359 25.76Z" fill="black"/>
        <path d="M120.578 26.4799C122.306 26.4799 123.386 25.496 123.914 24.752L124.01 26.12H127.154L127.082 18.2479C127.082 16.5679 126.146 15.0319 123.962 14.216C122.306 13.592 120.29 13.832 119.066 14.528C117.362 15.488 116.714 16.784 116.546 17.768L119.354 18.368C119.642 16.832 120.842 16.184 122.354 16.568C124.01 16.9999 123.746 18.368 123.41 18.632C123.074 18.896 121.682 19.016 120.506 19.184C117.914 19.52 116.09 20.696 116.354 23.312C116.618 25.256 118.034 26.4799 120.578 26.4799ZM121.946 23.96C120.53 24.248 119.498 23.816 119.498 22.6639C119.498 21.056 121.394 21.632 123.962 21.224C124.13 22.424 123.482 23.648 121.946 23.96Z" fill="black"/>
        <path d="M132.885 8.64795H129.549V26.1679H132.885V8.64795Z" fill="black"/>
        <path d="M134.8 20.1439C134.8 15.8479 137.92 13.904 141.112 13.904C144.28 13.904 147.376 15.848 147.016 20.984L138.064 21.08C138.064 22.016 138.952 23.552 140.92 23.672C142.36 23.768 143.272 23.096 143.8 22.088L146.752 23C146.152 24.464 144.28 26.336 141.16 26.336C137.32 26.336 134.8 23.5759 134.8 20.1439ZM138.064 18.7039L143.704 18.68C143.704 17.552 142.504 16.3279 140.632 16.4479C139.504 16.5199 138.208 17.36 138.064 18.7039Z" fill="black"/>
        <g clipPath="url(#clip0)">
          <path fillRule="evenodd" clipRule="evenodd" d="M2.77469 5.0792C0.848633 7.96175 0.848633 11.9745 0.848633 20C0.848633 28.0255 0.848633 32.0382 2.77469 34.9208C3.6085 36.1687 4.67994 37.2401 5.92783 38.0739C8.81038 40 12.8231 40 20.8486 40C28.8741 40 32.8868 40 35.7694 38.0739C37.0173 37.2401 38.0887 36.1687 38.9225 34.9208C40.8486 32.0382 40.8486 28.0255 40.8486 20C40.8486 11.9745 40.8486 7.96175 38.9225 5.0792C38.0887 3.83131 37.0173 2.75987 35.7694 1.92606C32.8868 0 28.8741 0 20.8486 0C12.8231 0 8.81038 0 5.92783 1.92606C4.67994 2.75987 3.6085 3.83131 2.77469 5.0792ZM19.8983 5.51184H21.5263L25.751 13.3015L30.5435 9.20594L31.6425 10.3049L27.5421 15.0925L35.3364 19.3219V20.9501L27.5657 17.7027L31.5771 29.7604L30.4215 30.9161L25.72 19.5037L21.576 34.4882H19.978L23.1778 19.4593L11.1629 30.8041L10.0442 29.6854L21.3842 17.6658L6.36004 20.8703V19.2722L21.3397 15.1236L9.93208 10.4268L11.0877 9.2711L23.1409 13.2778L19.8983 5.51184ZM11.1689 9.61422L23.6717 13.7704L11.1689 9.61422ZM35.0363 19.5005L35.0364 20.4996V19.5005L27.0729 15.1792L35.0363 19.5005ZM21.3477 5.81184H20.3486H21.3477L25.6641 13.7704L21.3477 5.81184Z" fill="black"/>
        </g>
        <defs>
          <clipPath id="clip0">
            <rect width="40" height="40" fill="white" transform="translate(0.848633)"/>
          </clipPath>
        </defs>
      </svg>
    )
}

export function UsernameFromIcon() {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.4005 11.6509C15.2992 10.9438 15.9551 9.97425 16.2771 8.87702C16.5991 7.77979 16.5711 6.60948 16.1969 5.52892C15.8228 4.44835 15.1212 3.51127 14.1897 2.84802C13.2582 2.18478 12.1431 1.82837 10.9996 1.82837C9.85613 1.82837 8.74107 2.18478 7.80957 2.84802C6.87806 3.51127 6.17645 4.44835 5.80233 5.52892C5.4282 6.60948 5.40018 7.77979 5.72216 8.87702C6.04414 9.97425 6.70011 10.9438 7.5988 11.6509C6.05887 12.2679 4.71523 13.2911 3.71112 14.6117C2.707 15.9322 2.08006 17.5004 1.89713 19.1492C1.88389 19.2696 1.89449 19.3914 1.92832 19.5077C1.96215 19.624 2.01856 19.7325 2.09432 19.827C2.24731 20.0178 2.46985 20.14 2.71296 20.1667C2.95608 20.1935 3.19986 20.1226 3.39068 19.9696C3.5815 19.8166 3.70372 19.594 3.73046 19.3509C3.93175 17.559 4.78618 15.9041 6.1305 14.7023C7.47482 13.5005 9.21479 12.8362 11.018 12.8362C12.8211 12.8362 14.5611 13.5005 15.9054 14.7023C17.2498 15.9041 18.1042 17.559 18.3055 19.3509C18.3304 19.5762 18.4379 19.7842 18.6071 19.9348C18.7764 20.0855 18.9955 20.1681 19.2221 20.1667H19.323C19.5633 20.1391 19.7829 20.0176 19.934 19.8287C20.0851 19.6398 20.1554 19.3989 20.1296 19.1584C19.9458 17.5049 19.3155 15.9326 18.3062 14.6101C17.297 13.2875 15.9468 12.2646 14.4005 11.6509ZM10.9996 11.0001C10.2744 11.0001 9.56552 10.785 8.96254 10.3821C8.35956 9.97923 7.88959 9.40658 7.61207 8.73658C7.33455 8.06659 7.26194 7.32934 7.40342 6.61808C7.5449 5.90681 7.89411 5.25347 8.40691 4.74068C8.9197 4.22789 9.57304 3.87867 10.2843 3.73719C10.9956 3.59572 11.7328 3.66833 12.4028 3.94585C13.0728 4.22337 13.6455 4.69334 14.0484 5.29632C14.4513 5.8993 14.6663 6.60821 14.6663 7.33341C14.6663 8.30587 14.28 9.2385 13.5924 9.92613C12.9047 10.6138 11.9721 11.0001 10.9996 11.0001Z" fill="black"/>
    </svg>
  )
}

export function EmailFormIcon() {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.4163 3.66675H4.58301C3.85366 3.66675 3.15419 3.95648 2.63846 4.4722C2.12274 4.98793 1.83301 5.6874 1.83301 6.41675V15.5834C1.83301 16.3128 2.12274 17.0122 2.63846 17.528C3.15419 18.0437 3.85366 18.3334 4.58301 18.3334H17.4163C18.1457 18.3334 18.8452 18.0437 19.3609 17.528C19.8766 17.0122 20.1663 16.3128 20.1663 15.5834V6.41675C20.1663 5.6874 19.8766 4.98793 19.3609 4.4722C18.8452 3.95648 18.1457 3.66675 17.4163 3.66675ZM4.58301 5.50008H17.4163C17.6595 5.50008 17.8926 5.59666 18.0645 5.76857C18.2364 5.94047 18.333 6.17363 18.333 6.41675L10.9997 10.8901L3.66634 6.41675C3.66634 6.17363 3.76292 5.94047 3.93483 5.76857C4.10674 5.59666 4.33989 5.50008 4.58301 5.50008ZM18.333 15.5834C18.333 15.8265 18.2364 16.0597 18.0645 16.2316C17.8926 16.4035 17.6595 16.5001 17.4163 16.5001H4.58301C4.33989 16.5001 4.10674 16.4035 3.93483 16.2316C3.76292 16.0597 3.66634 15.8265 3.66634 15.5834V8.50675L10.523 12.6959C10.6624 12.7764 10.8204 12.8187 10.9813 12.8187C11.1422 12.8187 11.3003 12.7764 11.4397 12.6959L18.333 8.50675V15.5834Z" fill="black"/>
    </svg>
  )
}

export function PasswordFormIcon() {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.0003 11.9166C10.7212 11.9133 10.4479 11.9956 10.217 12.1524C9.9861 12.3093 9.80879 12.533 9.70894 12.7937C9.60908 13.0543 9.59147 13.3392 9.65846 13.6102C9.72546 13.8811 9.87385 14.125 10.0837 14.3091V15.5833C10.0837 15.8264 10.1802 16.0595 10.3521 16.2314C10.5241 16.4033 10.7572 16.4999 11.0003 16.4999C11.2434 16.4999 11.4766 16.4033 11.6485 16.2314C11.8204 16.0595 11.917 15.8264 11.917 15.5833V14.3091C12.1268 14.125 12.2752 13.8811 12.3422 13.6102C12.4092 13.3392 12.3916 13.0543 12.2917 12.7937C12.1919 12.533 12.0146 12.3093 11.7837 12.1524C11.5528 11.9956 11.2794 11.9133 11.0003 11.9166ZM15.5837 8.24992V6.41659C15.5837 5.20101 15.1008 4.03522 14.2412 3.17568C13.3817 2.31614 12.2159 1.83325 11.0003 1.83325C9.78475 1.83325 8.61896 2.31614 7.75942 3.17568C6.89988 4.03522 6.41699 5.20101 6.41699 6.41659V8.24992C5.68765 8.24992 4.98817 8.53965 4.47245 9.05537C3.95672 9.5711 3.66699 10.2706 3.66699 10.9999V17.4166C3.66699 18.1459 3.95672 18.8454 4.47245 19.3611C4.98817 19.8769 5.68765 20.1666 6.41699 20.1666H15.5837C16.313 20.1666 17.0125 19.8769 17.5282 19.3611C18.0439 18.8454 18.3337 18.1459 18.3337 17.4166V10.9999C18.3337 10.2706 18.0439 9.5711 17.5282 9.05537C17.0125 8.53965 16.313 8.24992 15.5837 8.24992ZM8.25033 6.41659C8.25033 5.68724 8.54006 4.98777 9.05578 4.47204C9.57151 3.95632 10.271 3.66659 11.0003 3.66659C11.7297 3.66659 12.4291 3.95632 12.9449 4.47204C13.4606 4.98777 13.7503 5.68724 13.7503 6.41659V8.24992H8.25033V6.41659ZM16.5003 17.4166C16.5003 17.6597 16.4037 17.8929 16.2318 18.0648C16.0599 18.2367 15.8268 18.3333 15.5837 18.3333H6.41699C6.17388 18.3333 5.94072 18.2367 5.76881 18.0648C5.5969 17.8929 5.50033 17.6597 5.50033 17.4166V10.9999C5.50033 10.7568 5.5969 10.5236 5.76881 10.3517C5.94072 10.1798 6.17388 10.0833 6.41699 10.0833H15.5837C15.8268 10.0833 16.0599 10.1798 16.2318 10.3517C16.4037 10.5236 16.5003 10.7568 16.5003 10.9999V17.4166Z" fill="black"/>
    </svg>
  )
}

export function IconInfo(props) {
  const fill = props.fill || "#000000";
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.0004 3.5C7.30532 3.5 3.5 7.30532 3.5 11.9996C3.49988 13.1159 3.71967 14.2213 4.14682 15.2527C4.57397 16.284 5.2001 17.2212 5.98946 18.0105C6.77883 18.7999 7.71595 19.426 8.74733 19.8532C9.7787 20.2803 10.8841 20.5001 12.0004 20.5C16.6938 20.5 20.5 16.6947 20.5 11.9996C20.5 7.30532 16.6938 3.5 12.0004 3.5V3.5ZM12.7937 6.56869C13.6224 6.56869 13.8659 7.04945 13.8659 7.59927C13.8659 8.28543 13.317 8.92024 12.3794 8.92024C11.5949 8.92024 11.2213 8.52625 11.2443 7.87373C11.2443 7.32392 11.7038 6.56869 12.7937 6.56869V6.56869ZM10.6706 17.0904C10.104 17.0904 9.69051 16.746 10.0863 15.2365L10.7352 12.5582C10.8477 12.1297 10.8663 11.9579 10.7352 11.9579C10.5661 11.9579 9.8304 12.2537 9.39657 12.5458L9.11413 12.0828C10.4909 10.9327 12.0739 10.258 12.7512 10.258C13.3179 10.258 13.4117 10.9274 13.1293 11.9579L12.3856 14.7734C12.2537 15.271 12.3103 15.4428 12.4422 15.4428C12.6122 15.4428 13.1682 15.2374 13.7154 14.8062L14.0359 15.2365C12.6963 16.5752 11.2364 17.0904 10.6706 17.0904Z" fill={fill} />
    </svg>
  );
}

export function Info(props) {
  const color = props.color || "#ffffff";
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.00016 14.6667C11.6821 14.6667 14.6668 11.6819 14.6668 8.00004C14.6668 4.31814 11.6821 1.33337 8.00016 1.33337C4.31826 1.33337 1.3335 4.31814 1.3335 8.00004C1.3335 11.6819 4.31826 14.6667 8.00016 14.6667Z" stroke="white" strokeWidth="2"/>
      <path d="M8 4.66675H8.00708" stroke={color} strokeWidth="2" strokeLinecap="round"/>
      <path d="M6.66699 7.33337H8.00033V10.6667" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M6.66699 10.6667H9.33366" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export function CloseElement (props) {
  const fill = props.fill || "#000000";
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="15" cy="15" r="15" fill="#F2F2F2"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M10.0503 9.94979C9.6598 10.3403 9.6598 10.9734 10.0503 11.3639L13.586 14.8996L10.0504 18.4352C9.65995 18.8257 9.65995 19.4588 10.0504 19.8493C10.4409 20.2398 11.074 20.2398 11.4645 19.8493L15.0001 16.3137L18.5347 19.8482C18.9252 20.2387 19.5583 20.2387 19.9487 19.8482C20.3392 19.4577 20.3392 18.8246 19.9487 18.4342L16.4142 14.8996L19.9489 11.3649C20.3394 10.9744 20.3394 10.3413 19.9489 9.95084C19.5584 9.56036 18.9253 9.56036 18.5348 9.95084L15.0001 13.4855L11.4643 9.94979C11.0739 9.55931 10.4408 9.55931 10.0503 9.94979Z" fill={fill}/>
    </svg>
  )
}

export function ArrowDown (props) {
  const color = props.color || "#000000";
  return (
    <svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.04163 0C2.65102 0 2.3252 0.286428 2.29109 0.659781C2.17442 1.9368 2.15384 3.2201 2.22945 4.4997C2.04191 4.50964 1.85443 4.52117 1.66704 4.53428L0.535993 4.61339C0.0999025 4.64389 -0.143878 5.11045 0.0915549 5.46399C0.898008 6.67498 1.93715 7.72775 3.15358 8.56618L3.60671 8.87851C3.84173 9.0405 4.15827 9.0405 4.39329 8.87851L4.84642 8.56618C6.06285 7.72775 7.10199 6.67498 7.90845 5.46399C8.14388 5.11045 7.9001 4.64389 7.46401 4.61339L6.33296 4.53428C6.14557 4.52117 5.95809 4.50965 5.77055 4.4997C5.84616 3.2201 5.82558 1.9368 5.70891 0.659777C5.6748 0.286426 5.34897 0 4.95837 0H3.04163Z" fill={color}/>
    </svg>
  );
}

export function ArrowDownSmall(props) {
  const color = props.color || "#7C7C7C";
  return(
    <svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.04163 0C2.65102 0 2.3252 0.286428 2.29109 0.659781C2.17442 1.9368 2.15384 3.2201 2.22945 4.4997C2.04191 4.50964 1.85443 4.52117 1.66704 4.53428L0.535993 4.61339C0.0999025 4.64389 -0.143878 5.11045 0.0915549 5.46399C0.898008 6.67498 1.93715 7.72775 3.15358 8.56618L3.60671 8.87851C3.84173 9.0405 4.15827 9.0405 4.39329 8.87851L4.84642 8.56618C6.06285 7.72775 7.10199 6.67498 7.90845 5.46399C8.14388 5.11045 7.9001 4.64389 7.46401 4.61339L6.33296 4.53428C6.14557 4.52117 5.95809 4.50965 5.77055 4.4997C5.84616 3.2201 5.82558 1.9368 5.70891 0.659777C5.6748 0.286426 5.34897 0 4.95837 0H3.04163Z"
            fill={color}/>
    </svg>
  )
}

export function RoundedSlash(props) {
  const color = props.color || "#000000";
  return (
    <svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2 14L11 1.5" stroke={color} strokeWidth="3" strokeLinecap="round"/>
    </svg>
  );
}
