import React from "react";

import "./ButtonBase.css";

function ButtonBase(props) {
  const {classes, isLink=false, callback, href=null, content, icon=null, type="button"} = props;

  let result;

  if(isLink) {
    result = (
      <a href={href} onClick={callback} className={`button-base ${classes}`}>
        {icon !== null ?
          <>
            <span>{icon}</span>
            <span>{content}</span>
          </> : content
        }
      </a>
    );
  } else {
    result = (
      <button type={type} onClick={callback} className={`button-base ${classes}`}>
        {icon !== null ?
          <>
            <span>{icon}</span>
            <span>{content}</span>
          </> : content
        }
      </button>
    );
  }

  return (
    result
  );
}

export default ButtonBase;
