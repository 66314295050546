import React from "react";

import { Button, notification } from 'antd';
import axios from "axios";

import "./SignBlock.scss";
import {CloseElement} from "./Icons";

const googleImage = "https://storytale-public2.b-cdn.net/static/assets/account-app/assets/img/Logos/Google.svg"

export default function SignBlock(props) {
  const openNotificationWithIcon = (type, header, description) => {
    notification[type]({
      message: header,
      description: description,
      closeIcon: (<span><CloseElement/></span>),
    })
  }

  const redirectToGoogle = () => {
    axios.post(`/api/remoteAuth/googleRedirectLink`)
      .then(response => {
        if (response.data.success === true) {
          window.location = response.data.result.authorizedLink;
        } else {
          openNotificationWithIcon('warning', 'Error', response.data.message);
        }

      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <div className="SignBlock">
      <Button onClick={redirectToGoogle} type="primary"><img src={googleImage} alt="Google" />Authorize with Google</Button>
  </div>)
}
