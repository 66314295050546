import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import MainTitle from "../../components/MainTitle.tsx";
import SettingsSidebar from "../../components/SettingsSidebar";
import ModalSubmitDiscard from "../../components/ModalSubmitDiscard";
import EmptyPageFiller from "../../components/EmptyPageFiller";
import SubscriptionPlanCard from "../../components/SubscriptionPlanCard";
import generateTimePeriodCaption from '../../lib/generateTimePeriodCaption';

import axios from "axios";
import { Table } from "antd";
import { v4 as uuidv4 } from 'uuid';
import moment from "moment";

// Redux
import { connect, useDispatch } from 'react-redux';
import { authorizeAction } from '../../actions';

// Common js
import { settingsSidebarItems } from '../../lib/settingsSidebarItems';
import getSubscriptionsStatusName from '../../lib/statusIdNameCompare';
import { openNotificationWithIcon } from "../../lib/notifications";
import { postFormData } from '../../helpers/apiRequestsHelper';

// CSS styles
import './index.scss';
import '../../common_styles/settingsTable.scss';
import '../../common_styles/tableWrapper.scss';

// Images
const StandartNoSubscriptions = "https://storytale-public2.b-cdn.net/static/assets/account-app/assets/img/NoSubscriptions.png";
const BigNoSubscriptions = "https://storytale-public2.b-cdn.net/static/assets/account-app/assets/img/NoSubscriptions@2x.png";

// Redux mapStateToProps
const mapStateToProps = state => ({
  authorized: state.userReducer.authorized,
  subscriptionPlan: state.userReducer.subscriptionPlan,
  currentMembershipEndDate: state.userReducer.endDate,
});

function Subscriptions(props) {
  const [subscriptions, setSubscriptions] = useState([]);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [subscriptionID, setSubscriptionID] = useState(null);
  const [availablePlans, setAvailablePlans] = useState(null);
  const [envPlans, setEnvPlans] = useState(null);
  const [showPlans, setShowPlans] = useState(null);
  const [planStatus, setPlanStatus] = useState(null);
  const [currentPlanIndex, setCurrentPlanIndex] = useState(null);
  const [cardButtonsDisabled, setCardButtonsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const subsColumns = [
    {
      title: 'Plan',
      dataIndex: 'plan',
      key: 'plan'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status'
    },
    {
      title: 'Recurring',
      dataIndex: 'recurring',
      key: 'recurring'
    },
    {
      title: 'Next payment due date',
      dataIndex: 'enddate',
      key: 'enddate'
    },
    {
      title: '',
      dataIndex: '',
      key: 'view',
      render: (row) => <Link to={`/subscription/${row.id}`} className="settingsTable__link">View</Link>,
    },
    {
      title: '',
      dataIndex: '',
      key: 'cancel',
      render: (row) => {
        if (row.status === 'Active') return ( <span onClick={() => {setSubscriptionID(row.id); setShowCancelModal(true)}} className="settingsTable__link_destructive">Cancel</span>)
      },
    },
  ];

  function goAccount (id) {
      setCardButtonsDisabled(true);

      let formData = new FormData();
      formData.append('orderPositions[1][id]', id);
      formData.append('orderPositions[1][type]', 'subscription_plan');
      formData.append('orderPositions[1][count]', 1);

      postFormData('/api/order/create', formData)
          .then(data => {
              if (data.success && data.result.order.id) {
                  window.location.href = `/checkout/${data.result.order.id}`;
              } else {
                  openNotificationWithIcon('error', 'Error', data.message);
              }
          })
          .catch(err => {
              if (err.message === 'Request failed with status code 401') {
                  window.location.href = '.';
              } else {
                  openNotificationWithIcon('error', 'Error', err.message);
              }
          })
          .finally(() => {
              setCardButtonsDisabled(false);
          })
  }

  async function fetchAvailablePlans () {
    try {
      let availablePlans = await axios.get('/api/subscriptionPlan');
      if (availablePlans.status === 200 && availablePlans.data.success) {
        setAvailablePlans(availablePlans.data.result.subscriptionPlans);
      } else {
        console.log(availablePlans.data.message);
      }
    } catch (error){
      console.log(error);
    }
  }

  async function fetchSubscriptions () {
    try {
      let subscriptions = await axios.get('/api/subscription');
      if (subscriptions.status === 200 && subscriptions.data.success) {
        let  tableRows = [];
        subscriptions.data.result.subscriptions.forEach(element => {
          let paymentDateToShow = '';
          const paymentDate = element.nextBillDate;
          if (element.autoRenewal === true && (typeof paymentDate === 'string' || paymentDate instanceof String) && paymentDate !== '') {
            const paymentDateObj = new Date(paymentDate);
            if (paymentDateObj.toString() !== 'Invalid Date') {
              paymentDateObj.setHours(0,0,0,0);

              const todayObj = new Date();
              todayObj.setHours(0,0, 0, 0);

              const todayAbsolute = todayObj.getTime();
              const paymentDateAbsolute = paymentDateObj.getTime();

              if (paymentDateAbsolute >= todayAbsolute) {
                const paymentDateMoment = moment(paymentDateObj);
                paymentDateToShow = (paymentDateMoment.isValid ? paymentDateMoment.format("MMMM D, YYYY") : '');
              }
            }
          }

          let subsData;
          let fakeStatus;

          if (element.status === 2 && element.autoRenewal === false) {
            fakeStatus = 'Canceled';
          } else if (element.status === 2 && element?.currentMembership?.startDate === null) {
            fakeStatus = 'Pending';
          } else {
            fakeStatus = getSubscriptionsStatusName(element.status);
          }
          const chargeLabel = generateTimePeriodCaption(element.subscriptionPlan.chargePeriodCount, element.subscriptionPlan.chargePeriodLabel);

          subsData = {
            id: element.id,
            key: uuidv4(),
            plan: element.subscriptionPlan.name,
            _planId: element.subscriptionPlan.id,
            status: fakeStatus,
            recurring: `$${element.subscriptionPlan.price} / ${chargeLabel}`,
            enddate: paymentDateToShow,
          };
          tableRows.push(subsData);
        });
        setSubscriptions(tableRows.slice().sort((a, b) => b.id - a.id));
      } else {
        console.log(subscriptions.data.message);
      }
    } catch (error) {
      console.error(error);
    }
  }

 function handleSubsribeCancel(id) {
    axios.delete(`/api/subscription/${id}`)
    .then(function (response) {
      fetchSubscriptions().then(() => setShowCancelModal(false));
    })
    .catch(function (error) {
      console.log(error);
    });
  }

 function determinePlanStatus(currentMembershipEndDate, subscriptionPlan, showPlans) {
    try {
      if (currentMembershipEndDate === null || subscriptionPlan === false) {
        setPlanStatus('userHasNoPlan');
        return;
      }
      if (currentMembershipEndDate && subscriptionPlan.id && showPlans) {
        for (let i = 0; i < showPlans.length; i++ ) {
          if (showPlans[i].id && showPlans[i].id === subscriptionPlan.id) {
            setPlanStatus('userHasAvailablePlan');
            setCurrentPlanIndex(i);
            return;
          }
        }
        setPlanStatus('userHasOldPlan');
        return;
      }
      setPlanStatus(false);
    }
    catch (err) {
      console.log(err);
    }
 }

 function sortPlansByIds(plansToSort, idsArray) {
   let sortedPlans = [];

   plansToSort.forEach((plan) => {
     const newIndex = idsArray.indexOf(plan.id);
     if (newIndex !== -1) {
       sortedPlans[newIndex] = plan;
     }
   });

   return sortedPlans.filter((plan) => plan !== undefined);
 }

  useEffect(() => {
    dispatch(authorizeAction());
    const allRequests = Promise.all([fetchSubscriptions(), fetchAvailablePlans()]);
    allRequests.finally(() => setIsLoading(false));
  }, [dispatch]);

  useEffect(() => {
    setEnvPlans([
      parseInt(process.env.REACT_APP_PLAN_ID_FIRST) || null,
      parseInt(process.env.REACT_APP_PLAN_ID_SECOND) || null,
      parseInt(process.env.REACT_APP_PLAN_ID_THIRD) || null
    ])
  }, []);

  useEffect(() => {
    if (availablePlans) {
      const sortedPlans = sortPlansByIds(availablePlans, envPlans);
      setShowPlans(sortedPlans);
    }
  }, [availablePlans, envPlans]);

  useEffect(() => {
    if (showPlans && props.subscriptionPlan !== undefined) {
      if (showPlans.length) {
        determinePlanStatus(props.currentMembershipEndDate, props.subscriptionPlan, showPlans);
      }
    }
  }, [props.currentMembershipEndDate, props.subscriptionPlan, showPlans]);

  return (
    <div className="container subscriptions">
      <ModalSubmitDiscard
        handleClose={() => setShowCancelModal(false)}
        handleSubmit={(id) => handleSubsribeCancel(id)}
        show={showCancelModal}
        description={`Do you really want to cancel subscription #${subscriptionID}?`}
        submitButtonText="Cancel subscription"
        cancelButtonText="I changed my mind"
        target={subscriptionID}
        submitButtonVariant="outline-danger"
        cancelButtonVariant="primary"
      />
      <div className="row">
        <div className="col-12 col-md-2">
          <SettingsSidebar items={settingsSidebarItems} />
        </div>
        { !isLoading
        ?<div className="col-12 offset-0 col-md-9 offset-md-1">
            { subscriptions.length
            ? <>
                  <MainTitle text="Subscriptions" level="h1" />

                  {planStatus && planStatus === "userHasNoPlan" && <>
                    <div className={"subscriptions__plan-cards-no-current"}>
                      {showPlans && showPlans.map((plan, index) => {
                        const status = (index === 1 ? 'best' : 'other');
                        const buttonCaption = 'Subscribe';
                        return (
                            <SubscriptionPlanCard
                                id={plan.id}
                                name={plan.name}
                                durationCount={plan.durationCount}
                                duration={plan.durationLabel}
                                chargePeriodCount={plan.chargePeriodCount}
                                chargePeriodLabel={plan.chargePeriodLabel}
                                price={plan.price}
                                downloadLimit={plan.downloadLimit}
                                status={status}
                                buttonCaption={buttonCaption}
                                key={index}
                                buttonDisabled={cardButtonsDisabled}
                                handleClick={() => { goAccount(plan.id) }}
                            />
                        )
                      })}
                    </div>
                  </>}

                  {planStatus && planStatus === "userHasOldPlan" && <>
                    <SubscriptionPlanCard
                        id={props.subscriptionPlan.id}
                        name={props.subscriptionPlan.name}
                        durationCount={props.subscriptionPlan.durationCount}
                        chargePeriodCount={props.subscriptionPlan.chargePeriodCount}
                        chargePeriodLabel={props.subscriptionPlan.chargePeriodLabel}
                        duration={props.subscriptionPlan.durationLabel}
                        price={props.subscriptionPlan.price}
                        downloadLimit={props.subscriptionPlan.downloadLimit}
                        status={'current-old'}
                    />
                    <p className={"subscriptions__section-title"}>Switch to</p>
                    <div className={"subscriptions__plan-cards-no-current"}>
                      {showPlans && showPlans.map((plan, index) => {
                        let status = (index === 1 ? 'best' : 'other');
                        const buttonCaption = 'Switch to this';
                        return (
                          <SubscriptionPlanCard
                            id={plan.id}
                            name={plan.name}
                            durationCount={plan.durationCount}
                            chargePeriodCount={plan.chargePeriodCount}
                            chargePeriodLabel={plan.chargePeriodLabel}
                            duration={plan.durationLabel}
                            price={plan.price}
                            downloadLimit={plan.downloadLimit}
                            status={status}
                            buttonCaption={buttonCaption}
                            buttonDisabled={cardButtonsDisabled}
                            key={index}
                            tooltipText={'By switching the plan you get the remaining downloads from the last subscription.'}
                            handleClick={() => { goAccount(plan.id) }}
                          />
                        )
                      })}
                    </div>
                  </>}

                  {planStatus && planStatus === "userHasAvailablePlan" && <div className={"subscriptions__plan-cards-with-current"}>
                    {showPlans && showPlans.map((plan, index) => {
                      let status;
                      let buttonCaption;
                      let tooltipText;
                      if (index === currentPlanIndex) {
                        status = 'current';
                        buttonCaption = 'Update limits';
                        tooltipText = 'Your subscription period will start over and remaining downloads will be added to the monthly download number.';
                      }
                      if (index > currentPlanIndex) {
                        status = 'other';
                        buttonCaption = 'Upgrade';
                        tooltipText = 'By switching the plan you get the remaining downloads from the last subscription.';
                      }
                      if (index < currentPlanIndex) {
                        status = 'other';
                        buttonCaption = 'Downgrade';
                        tooltipText = 'By switching the plan you get the remaining downloads from the last subscription.';
                      }
                      return (
                          <SubscriptionPlanCard
                              id={plan.id}
                              name={plan.name}
                              durationCount={plan.durationCount}
                              duration={plan.durationLabel}
                              chargePeriodCount={plan.chargePeriodCount}
                              chargePeriodLabel={plan.chargePeriodLabel}
                              price={plan.price}
                              downloadLimit={plan.downloadLimit}
                              status={status}
                              buttonCaption={buttonCaption}
                              buttonDisabled={cardButtonsDisabled}
                              key={index}
                              handleClick={() => { goAccount(plan.id) }}
                              tooltipText={tooltipText}
                          />
                      )
                    })}
                  </div>}

                <MainTitle text="History" level="h2" />
                <div className="tableWrapper  subscriptions__table">
                  <Table
                    className="settingsTable"
                    columns={subsColumns}
                    dataSource={subscriptions}
                    pagination={false}
                  />
                </div>
              </>
            : (<EmptyPageFiller
                text='You have no subscriptions yet'
                StandartImage={StandartNoSubscriptions}
                BigImage={BigNoSubscriptions}
                linkText='Explore pricing'
                linkPath='plans'
                buttonType='secondary'
                showIcon={false}
              />)}
        </div>
        : null}
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(Subscriptions);
