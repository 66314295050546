import 'antd/dist/antd.css';
import './AccountForm.scss';  

import LinksBlock from '../components/LinksBlock';
import ErrorField from '../components/ErrorField';
import SuccessField from './SuccessField';

import { Form, Input, Button } from 'antd';
import axios from "axios";
import React, { useState } from "react";

export default function RestorePasswordForm () {
  const [backError, setBackError] = useState({
    message: 'Unknown Error',
    show: false,
  });
  const [backSuccess, setBackSuccess] = useState({
    message: 'Success',
    show: false,
  });

  const onFinish = (values) => {
    axios.post(`/api/user/startResetPassword?login=${values.usernameOrEmail}`)
      .then(response => {
        if (response.data.success === true) {
          setBackSuccess({
            message: 'Reset password request was successfully sent. Please check you email',
            show: true,
          });
          setBackError({
            message: null,
            show: false,
          });
        } else {
          setBackError({
            message: response.data.message,
            show: true,
          });
          setBackSuccess({
            message: null,
            show: false,
          });
        }
      })
      .catch(function (error) {
        setBackError({
          message: error.message,
          show: true,
        });
        setBackSuccess({
          message: null,
          show: false,
        });
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Form
      name="basic"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      className="AccountForm"
    >

      <Form.Item
        label="Username or Email address"
        name="usernameOrEmail"
        rules={[
          {
            required: true,
            message: 'Please input your username!',
          },
        ]}
      >
        <Input />
      </Form.Item>

      { backError.show === true &&
        <ErrorField error_message={backError.message} show={backError.show}/>
      }   

      { backSuccess.show === true &&
        <SuccessField message={backSuccess.message} show={backSuccess.show}/>
      }  

      <Form.Item>
        <Button type="primary" htmlType="submit">
          Get New Password
        </Button>
      </Form.Item>

      <LinksBlock
        firstLink='/login'
        firstText='Log in'
        secondLink='/register'
        secondText='Register'
      />

    </Form>
  );
};
