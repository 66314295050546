import React from "react";
import "./IllustrationsList.css"
import Product from "./Product";
import Skeleton from '@material-ui/lab/Skeleton';
import { LazyLoadComponent } from 'react-lazy-load-image-component';

function IllustrationsList(props) {
    const {
        pictures,
        onClick,
        customerActivitys,
        containerType,
        downloadErrorHandler,
        getCustomerActivities,
        downloadCallback,
        likeCallback,
        picturesLikesCounts,
    } = props;

    function renderCards() {
        const items = [];

        for (let x = 0; x < 12; x++) {
            items.push(
                <Skeleton variant="rect" width="100%" key={x} animation="wave">
                    <span style={{display: 'block', paddingTop: '75%'}}/>
                </Skeleton>)
        }

        return (
            <>
                {items}
            </>
        );
    }

    return (
        <>
            <div className={`illustrations-list illustrations-list-${containerType}`}>
                {props.status ? renderCards() :
                    <>
                        {pictures.length > 0
                            ? pictures.map((picture, index) => (
                            <LazyLoadComponent threshold={300} key={index}>
                                <Product
                                    id={picture.id}
                                    cover={picture.cover}
                                    assetName={picture.name}
                                    packName={picture.pack ? picture.pack.name : ''}
                                    isFree={picture.isFree}
                                    likeCount={picturesLikesCounts ? picturesLikesCounts[picture.id] : null}
                                    onClick={onClick}
                                    customerActivitys={customerActivitys}
                                    containerType={containerType}
                                    isLiked={picture.isLiked}
                                    isDownloaded={picture.isDownloaded}
                                    downloadErrorHandler={downloadErrorHandler}
                                    getCustomerActivities={getCustomerActivities}
                                    downloadCallback={downloadCallback}
                                    likeCallback={likeCallback}
                                />
                            </LazyLoadComponent>
                            ))
                            :
                            null
                        }
                    </>
                }
            </div>
        </>
    );
}

export default IllustrationsList;

