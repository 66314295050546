export const statusIdNameCompare = {
  1: 'New',
  2: 'Confirmed',
  3: 'Paid',
  'default': 'Status unknown'
}

export default function getOrderStatusName(id) {
  return (statusIdNameCompare[id] || statusIdNameCompare['default'])
};
