import axios from "axios";

import getCustomerActivities from "./getCustomerActivities";

const loadMoreIllustrationsFrom = async (illustrationArray, setIllustrationArray, paramsList, setParamsList, customerActivities, setCustomerActivities) => {

  let paramsListObj = Object.assign({}, paramsList);
  paramsListObj.page = paramsListObj.page += 1;

  setParamsList(paramsListObj, (paramsListObj) => {

    let search = {
      params: paramsListObj
    };

    axios
      .get(`/api/illustration`, search)
      .then(response => {
        const {success} = response.data;
        const {illustrationData} = response.data.result;

        try {
          if (success && illustrationData !== null) {
            const illustrationsClone = [...illustrationArray];
            const result = illustrationsClone.concat(illustrationData);

            setIllustrationArray(result, (result) => {
              getCustomerActivities(result, customerActivities, setCustomerActivities);
            })
          }
        } catch (err) {
          console.log(err);
        }
      }).catch(function (error) {
      console.log(error);
    });
  })
}

export default loadMoreIllustrationsFrom ;