import axios from "axios";

const initialState = {
  moods: [],
}

export default function browseReducer(state = initialState, action) {
  switch (action.type) {
    case 'browse/moodsLoad': {

      return {
        ...state,
        moods: action.payload,
      }
    }
    default:
      return state
  }
}

// Thunk function
export async function fetchMoodsData(dispatch, _getState) {
  await axios.get(`/api/mood`).then((response) => {
    const {success, result} = response.data;
    const {moodsData} = result;

    if(success) {
      dispatch({ type: 'browse/moodsLoad', payload: moodsData})
    }
  })
    .catch((error) => {
      console.log(error)
    })
}
