import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import PropTypes from "prop-types";

import "../common_styles/buttons.scss";
import "./Modal.scss"

const ModalSubmitDiscard = ({ 
    show, 
    handleSubmit, 
    handleClose, 
    description, 
    submitButtonText, 
    cancelButtonText,
    submitButtonVariant,
    cancelButtonVariant,
    target }) =>
  (
    <Modal 
      show={show} 
      onHide={handleClose}
      dialogClassName="modal-dialog modal-dialog-subscribtion modal-dialog-centered"
      >
      <Modal.Header closeButton />
      <Modal.Body>
        <p className="description">{description}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant={submitButtonVariant} onClick={() => handleSubmit(target)}>
          {submitButtonText}
        </Button>
        <Button variant={cancelButtonVariant} onClick={handleClose}>
          {cancelButtonText}
        </Button>
      </Modal.Footer>
    </Modal>
  )  

ModalSubmitDiscard.propTypes = {
  submitButtonText:     PropTypes.string,
  cancelButtonText:     PropTypes.string,
  submitButtonVariant:  PropTypes.string,
  cancelButtonVariant:  PropTypes.string,
};
  
  
ModalSubmitDiscard.defaultProps = {
  submitButtonText:     "OK",
  cancelButtonText:     "Cancel",
  submitButtonVariant:  "danger",
  cancelButtonVariant:  "primary",
};  

export default ModalSubmitDiscard;
