import React from 'react';
import SubTitle from './SubTitle.tsx';

import 'antd/dist/antd.css';
import './AccountForm.scss';  

import { Form, Input, Button, notification } from 'antd';

import axios from "axios";
import {CloseElement} from "./Icons";

export default function SettingsForm() {
  const openNotificationWithIcon = (type, header, description) => {
    notification[type]({
      message: header,
      description: description,
      closeIcon: (<span><CloseElement/></span>),
    })
  }

  const onFinish = (values) => {
    let formData = new FormData();

    formData.append('password', values.oldPassword);
    formData.append('newPassword', values.password);

    axios.post(`api/user/settings/changePassword`, formData)
      .then(response => {
        if (response.data.success === true) {
          openNotificationWithIcon('success', 'Success', 'Password was changed');
        } else {
          openNotificationWithIcon('error', 'Error', response.data.message);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Form
      name="SettingsForm"
      initialValues={{remember: true}}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      className="AccountForm"
    >

      <SubTitle text='Password'/>

      <Form.Item label="Old password"
        name="oldPassword"
        rules={[{
          required: true,
          message: 'Please input your old password!',
        }]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item label="New password"
        name="password"
        rules={[{
          required: true,
          message: 'Please input your password!',
        }]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item
        name="confirm"
        label="Confirm new password"
        dependencies={['password']}
        hasFeedback
        rules={[
          {
            required: true,
            message: 'Please confirm your password!',
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('New password and confirm do not match!'));
            },
          }),
        ]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit">
          Save changes
        </Button>
      </Form.Item>
    </Form>
  );
};
