import axios from "axios";

const getIllustrationInfo = async (
  id,
  illustrationsFromKeywordsParams,
  illustrationsFromPackParams,
  setIllustrationsFromKeywordsParams,
  setIllustrationsFromPackParams,
  setIsLoadingKeywordsIllustrations,
  setIsLoadingPackIllustrations,
  setIsLoadingInfoData,
  setIsLoadingPackInfo,
  setIllustrationsFromKeywords,
  setIllustrationsFromPack,
  getCustomerActivities,
  setCurrentIllustrationInfo,
  setShowModalInfo,
  setCurrentIllustrationPackData
) => {

  //clear loading indicators and state

  //get more information from current asset
  const responseIllustration = await axios.get(
    `/api/illustration/${id}`
  );

  const {illustrationData} = await responseIllustration.data.result;
  const {keywords = []} = illustrationData;
  const packId = illustrationData.pack.id;

  //KEYWORDS
  try {
    let strUpd = keywords.join(' ').trim();
    if (strUpd.length > 0) {

      let illustrationsFromKeywordsParamsObj = Object.assign({}, illustrationsFromKeywordsParams);
      illustrationsFromKeywordsParamsObj.searchText = strUpd;

      setIllustrationsFromKeywordsParams(illustrationsFromKeywordsParamsObj , (illustrationsFromKeywordsParams) => {

        let search = {
          params: illustrationsFromKeywordsParams
        };

        axios
          .get(`/api/illustration`, search)
          .then(response => {
            const {illustrationData} = response.data.result;
            const {success} = response.data;


            if (success && illustrationData !== null) {
              setIllustrationsFromKeywords(illustrationData, (data) => {
                setIsLoadingKeywordsIllustrations(false);
                getCustomerActivities(data);
              })
            }
          }).catch(err => {
          console.log(err);
        });
      });
    }
  } catch (err) {
    console.log(err);
  }

 // ASSET INFO
  try {
    if (responseIllustration.data.success === true && (illustrationData !== null)) {

      setCurrentIllustrationInfo(illustrationData , () => {
        setShowModalInfo(true);
        setIsLoadingInfoData(false);
      });
    }
  } catch (err) {
    console.log(err);
  }


//CURRENT PACK INFORMATION
  try {
    if(packId && packId !== '') {
      const responsePackInfo = await axios.get(
        `/api/pack/${packId}`
      );

      const {packData} = await responsePackInfo.data.result;

      if (responsePackInfo.data.success === true && (packData !== null)) {
        setCurrentIllustrationPackData(packData);
        setIsLoadingPackInfo(false);
      }
    }
  } catch (err) {
    console.log(err);
  }

  //GET ASSETS FROM PACK
  try {
    if(packId && packId !== '') {

      let illustrationsFromPackParamsObj = Object.assign({}, illustrationsFromPackParams);
      illustrationsFromPackParamsObj.packId = packId;

      setIllustrationsFromPackParams(illustrationsFromPackParamsObj, (illustrationsFromPackParams) => {

        let search = {
          params: illustrationsFromPackParams
        };

        axios
          .get(`/api/illustration`, search)
          .then(response => {
            const {illustrationData} = response.data.result;
            const {success} = response.data;

            if (success && illustrationData !== null) {

              setIllustrationsFromPack(illustrationData , (illustrationData) => {
                setIsLoadingPackIllustrations(false);
                getCustomerActivities(illustrationData);
              })
            }
          }).catch((error) => {
          console.log(error);
        });
      });
    }
  } catch (err) {
    console.log(err);
  }
};

export default getIllustrationInfo;
