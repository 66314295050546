import axios from "axios";

const initialState = {
  hasSubscribe: null,
  authorized : null,
  downloadRemaining : '',
  autoRenewal: null,
  endDate: '',
  userName: '',
  userEmail: '',
  subscriptionPlan: false,
  membershipStatus: null,
};

function _setLocalStorageWithDate(name, value) {
  let _timeNow = Date.now();

  localStorage.setItem(name, value);
  localStorage.setItem(`${name}_date`, _timeNow);
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case 'user/authorization': {
      const status = action.payload;
      switch (status) {
        case 401: {
          return {
            ...state,
            authorized: false,
          }
        }
        case true: {
          return {
            ...state,
            authorized: true,
          }
        }
        default:
          return state
      }
    }
    case 'user/name': {
      if (action.payload === 401) {
        return {
          ...state,
          userName: '',
        }
      }
      return {
        ...state,
        userName: action.payload
      }
    }
    case 'user/email': {
      if (action.payload === 401) {
        return {
          ...state,
          userEmail: '',
        }
      }
      return {
        ...state,
        userEmail: action.payload
      }
    }
    case 'user/subscribeStatus': {
      return {
        ...state,
        hasSubscribe: action.payload,
      }
    }
    case 'user/subscriptionPlan': {
      const plan = action.payload[0];

      if (plan) {
        return {
          ...state,
          subscriptionPlan: plan.subscriptionPlan,
        }
      } else {
        return {
          ...state,
          subscriptionPlan: false,
        }
      }
    }
    case 'user/autoRenewal': {
      return {
        ...state,
        autoRenewal: action.payload,
      }
    }
    case 'user/endDate': {
      return {
        ...state,
        endDate: action.payload,
      }
    }
    case 'user/downloadRemaining': {
      return {
        ...state,
        downloadRemaining: action.payload,
      }
    }
    case 'user/membershipStatus': {
      return {
        ...state,
        membershipStatus: action.payload,
      }
    }
    default:
      return state
  }
}

// Thunk function
export async function checkAuthorization(dispatch) {
  await axios.get(
    `/api/userInfo/validateSession`
  ).then((response) => {
    dispatch({ type: 'user/authorization', payload: response.data.success });
    _setLocalStorageWithDate('storyAvtorizedUser', true);
    dispatch({ type: 'user/name', payload: response.data.result.user.name});
    localStorage.setItem('storyUserName', response.data.result.user.name);
    dispatch({ type: 'user/email', payload: response.data.result.email});
    localStorage.setItem('storyUserEmail', response.data.result.email);
  })
  .catch((error) => {
    dispatch({ type: 'user/authorization', payload: error.response.status });
    _setLocalStorageWithDate('storyAvtorizedUser', false);
    dispatch({ type: 'user/name', payload: error.response.status});
    localStorage.setItem('storyUserName', '');
    dispatch({ type: 'user/email', payload: error.response.status});
    localStorage.setItem('storyUserEmail', '');
    localStorage.setItem('world', 'goodbye');
  })
}

// Thunk function
export async function userLogout(dispatch) {
  localStorage.setItem('storyAvtorizedUser', false);

  await axios.get(
    `/api/user/logout`
  ).then((response) => {
    const {success} = response.data;
    const errorStatus = 401;
    if(success) {
      dispatch({ type: 'user/authorization', payload: errorStatus});
      dispatch({ type: 'user/downloadRemaining', payload: ''});
      dispatch({ type: 'user/autoRenewal', payload: null});
      dispatch({ type: 'user/endDate', payload: ''});
      dispatch({ type: 'user/membershipStatus', payload: null });
      dispatch({ type: 'user/subscribeStatus', payload: undefined });
      dispatch({ type: 'user/subscriptionPlan', payload: [] });
    }
  })
  .catch((error) => {
    console.log(error)
  })
}

// Thunk function
export async function getActiveSubscriptionInfo(dispatch) {
  await axios.get(
    `/api/subscription?status=2`
  )
    .then((response) => {
      if (response.data.success && response.data.result && Array.isArray(response.data.result.subscriptions) && response.data.result.subscriptions.length === 1) {
        const {downloadRemaining, endDate, status} = response.data.result.subscriptions[0].currentMembership;
        const {autoRenewal} = response.data.result.subscriptions[0];
        dispatch({ type: 'user/subscribeStatus', payload: true });
        dispatch({ type: 'user/subscriptionPlan', payload: response.data.result.subscriptions });
        dispatch({ type: 'user/autoRenewal', payload: autoRenewal});
        dispatch({ type: 'user/downloadRemaining', payload: downloadRemaining});
        dispatch({ type: 'user/endDate', payload: endDate});
        dispatch({ type: 'user/membershipStatus', payload: status });
      } else {
        dispatch({ type: 'user/subscribeStatus', payload: false });
      }
    })
    .catch((error) => {
      dispatch({ type: 'user/subscribeStatus', payload: undefined });
      dispatch({ type: 'user/subscriptionPlan', payload: [] });
      console.error(error);
    })
}

