export const settingsSidebarItems = [
  {
    name: 'Settings',
    path: '/settings'
  },
  {
    name: 'Orders',
    path: '/orders'
  },
  {
    name: 'Notifications',
    path: '/notifications'
  },
  {
    name: 'Subscriptions',
    path: '/subscription'
  },
  {
    name: 'Logout',
    path: '/logout'
  }
];
