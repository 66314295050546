import React, {useState, useEffect} from 'react';
import { Redirect } from 'react-router-dom';

import LoginForm from '../../components/LoginForm';
import MainTitle from '../../components/MainTitle.tsx';
import Picture from '../../components/Picture';

import { getCookie } from '../../lib/cookies';

// Redux
import { connect } from 'react-redux';

import './index.scss';

// Redux mapStateToProps
const mapStateToProps = state => ({
  authorized: state.userReducer.authorized,
})

const images = [
  "https://storytale-public2.b-cdn.net/static/assets/account-app/assets/img/SampleImages/Image1@2x.png",
  "https://storytale-public2.b-cdn.net/static/assets/account-app/assets/img/SampleImages/Image2@2x.png",
  "https://storytale-public2.b-cdn.net/static/assets/account-app/assets/img/SampleImages/Image3@2x.png",
  "https://storytale-public2.b-cdn.net/static/assets/account-app/assets/img/SampleImages/Image4@2x.png",
  "https://storytale-public2.b-cdn.net/static/assets/account-app/assets/img/SampleImages/Image5@2x.png",
  "https://storytale-public2.b-cdn.net/static/assets/account-app/assets/img/SampleImages/Image6@2x.png",
  "https://storytale-public2.b-cdn.net/static/assets/account-app/assets/img/SampleImages/Image7@2x.png",
  "https://storytale-public2.b-cdn.net/static/assets/account-app/assets/img/SampleImages/Image8@2x.png"
];

function Login({authorized}) {
  const [randomPicture, setRandomPicture] = useState(null);

  const generateNumber = (min, max) => {
    return Math.floor(min + Math.random() * (max + 1 - min) - 1);
  }

  useEffect(() => {
    setRandomPicture(generateNumber(1, 8));
  }, []);

  if (authorized === null) return null
  if (!getCookie('sessionUuid')) {
    return (
      <div className='container login'>
        <div className='row'>
          <div className='col-12'>
            <MainTitle text='Sign in' />
          </div>
          <div className='col-12 col-lg-4'>
            <LoginForm />
          </div>
          <div className='col-12 offset-0 offset-md-1 col-md-7'>
            { randomPicture !== null &&
              <div className='login__picture-block'>
                <Picture standart={images[randomPicture]} big={images[randomPicture]}/>
              </div>
            }
          </div>
        </div>
      </div>
    )
  } else {
    return <Redirect to='/settings' />
  }
}

export default connect(mapStateToProps)(Login);
