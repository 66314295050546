import React from 'react';
import PropTypes from 'prop-types';

import './SuccessField.css';

function SuccessField({ 
  message,
  show 
}) {
  if (!show) return null;
  return (<div className="SuccessField">{message}</div>);
}
SuccessField.propTypes = {
  message: PropTypes.string,
  show: PropTypes.bool,
};

SuccessField.defaultProps = {
  message: 'Success',
  show: false,
};

export default SuccessField;
