import { useEffect, useState, Fragment } from 'react';
import { Link } from "react-router-dom";
import { useParams } from "react-router";

import MainTitle from "../../components/MainTitle.tsx";
import SubTitle from "../../components/SubTitle.tsx";
import SettingsSidebar from "../../components/SettingsSidebar";
import { BackwardSvg } from "../../components/Icons";
import Button from "react-bootstrap/Button";
import InfoField from "../../components/InfoField";
import ModalSubmitDiscard from "../../components/ModalSubmitDiscard";

import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";

//Common js
import { settingsSidebarItems } from "../../lib/settingsSidebarItems";
import getSubscriptionsStatusName from "../../lib/statusIdNameCompare";
import generateTimePeriodCaption from "../../lib/generateTimePeriodCaption";

// Redux
import { useDispatch } from "react-redux";
import { authorizeAction } from "../../actions";

import "./SubscriptionSingle.scss";

function SubscriptionSingle() {
  const { id } = useParams();
  const [subscriptionDetails, setSubscriptionDetails] = useState(null);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [endDateMoment, setEndDateMoment] = useState(moment(null));
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(authorizeAction());
    fetchSubscriptionDetails(id);
  }, [dispatch, id]);

  async function fetchSubscriptionDetails(id) {
    try {
      let subscriptionDetails = await axios.get(`/api/subscription/${id}`);
      if (subscriptionDetails.status === 200) {
        if (subscriptionDetails.data.success)
          setSubscriptionDetails(subscriptionDetails.data.result.subscription);
          setEndDateMoment(moment(subscriptionDetails.data.result.subscription.currentMembership.endDate));
          setIsLoading(false);
      } else {
        console.log(subscriptionDetails.data.message);
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  }

  function handleSubsribeCancel(id) {
    let formData = new FormData();

    formData.append('id', id);

    axios.post('/api/subscription/unsubscribe', formData)
    .then(function (response) {
      fetchSubscriptionDetails(id).then(setShowCancelModal(false));
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  return (
    <div className="container subscription">
       <ModalSubmitDiscard
        handleClose={() => setShowCancelModal(false)}
        handleSubmit={(id) => handleSubsribeCancel(id)}
        show={showCancelModal}
        description={`Do you really want to cancel subscription #${id}?`}
        submitButtonText="Cancel subscription"
        cancelButtonText="I’ve change my mind"
        target={id}
        submitButtonVariant="outline-danger"
        cancelButtonVariant="primary"
      />
      <div className="row">
        <div className="col-12 col-md-2">
          <SettingsSidebar items={settingsSidebarItems} />
        </div>
        <div className="col-12 offset-0 col-md-9 offset-md-1">
          <Link to="/subscription">
            <Button variant="outline_light" className="subscription__backward-button">
              <BackwardSvg className="btn__icon_right" />
              Subscriptions
            </Button>
          </Link>
          { !isLoading
            ? subscriptionDetails !== null
              ?  <Fragment>
                  <MainTitle text="Subscription"/>
                  <SubTitle text="Details"/>
                  <InfoField key={uuidv4()}
                    label="Recurring"
                    value={`$${subscriptionDetails.subscriptionPlan.price} / ${generateTimePeriodCaption(subscriptionDetails.subscriptionPlan.chargePeriodCount, subscriptionDetails.subscriptionPlan.chargePeriodLabel)}`}
                  />
                  <InfoField key={uuidv4()}
                    label="Subscription Plan"
                    value={subscriptionDetails.subscriptionPlan.name}
                  />
                  <InfoField key={uuidv4()}
                    label="Status"
                    value={subscriptionDetails.status === 2 && !subscriptionDetails.autoRenewal ? 'Canceled' : getSubscriptionsStatusName(subscriptionDetails.status)}
                  />
                  <InfoField key={uuidv4()}
                    label="Download limit"
                    value={`${subscriptionDetails.subscriptionPlan.downloadLimit} / ${generateTimePeriodCaption(subscriptionDetails.subscriptionPlan.durationCount, subscriptionDetails.subscriptionPlan.durationLabel)}`}
                  />

                  {subscriptionDetails.subscriptionPlan.durationCount === subscriptionDetails.subscriptionPlan.chargePeriodCount ?
                    (subscriptionDetails.currentMembership.endDate && (
                      <InfoField key={uuidv4()}
                                 label="Next payment due date"
                                 value={endDateMoment.isValid() ? endDateMoment.format("MMMM D, YYYY") : ""}
                      />))
                    : null}

                  { subscriptionDetails.status === 2 ?
                    subscriptionDetails.autoRenewal === true ? <Button variant="danger" className="subscription__cancel-button" onClick={() => setShowCancelModal(true) }>Cancel subscription</Button>
                    : null
                  : null }
                </Fragment>
            : <Fragment>
                <MainTitle text="Subscription not found"/>
                <SubTitle text="No subscription with this id"/>
              </Fragment>
            : null
          }
        </div>
      </div>
    </div>
  )
}

export default SubscriptionSingle;
