import axios from "axios";
import {openNotificationWithIcon} from "./notifications";

const b64toBlob = (b64Data, contentType='', sliceSize=512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, {type: contentType});
  return blob;
}

const illustrationDownload = (id, downloadErrorHandlerWrapper, downloadClick, customerActivities, callback = () => {}) => {

  axios({
    url: `/api/illustration/download/${id}`,
    method: 'GET',
  }).then((response) => {
    if (!response.data.success) {
      downloadErrorHandlerWrapper(response.data.code, response.data.message);
    } else {
      downloadClick();
      const url = window.URL.createObjectURL(new Blob([b64toBlob(response.data.result.zip)], {
        type: 'application/zip'
      }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'file.zip');
      document.body.appendChild(link);
      link.click();
      const {success} = response.data;
      customerActivities(id, "isDownloaded", success);
    }
  }).catch(function (error) {
    error.request.status === 401 && callback()
  });
}

export default illustrationDownload;
