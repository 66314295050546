import React from 'react';
import moment from "moment";
import {Navbar} from "react-bootstrap";
import {Link} from "react-router-dom";
import {useSelector, useDispatch} from 'react-redux';
import {Collapse} from 'antd';
import {userLogout} from "../reducers/userSlice";
import {StorytaleLogo} from "./Icons";
import TextAvatar from './TextAvatar.js';
import goMarket from "../lib/goMarket";
import './Header.css';
import '../common_styles/tableWrapper.scss';

const {Panel} = Collapse;

const Header = () => {
  const authorizationStatus = useSelector((state) => state.userReducer.authorized);
  const subscriptionStatus = useSelector((state) => state.userReducer.hasSubscribe);
  const downloadRemaining = useSelector((state) => state.userReducer.downloadRemaining);
  const membershipStatus = useSelector((state) => state.userReducer.membershipStatus);
  const userEmail= useSelector((state) => state.userReducer.userEmail);
  const endDate = useSelector((state) => state.userReducer.endDate);
  const dispatch = useDispatch();

  const formattedDate = moment(endDate).isValid() ? moment(endDate).format("MMMM D") : '';

  const FindSelector = (value) => {
    const todo = useSelector((state) => state['browseReducer'].moods.filter(mood => mood.name.toLowerCase() === value.toLowerCase()).map(item => item.id));
    return todo[0];
  };

  const logoutClick = () => dispatch(userLogout);

  const mood3d = FindSelector('3d');
  const moodOutline = FindSelector('outline');
  const moodFlat = FindSelector('flat');
  const moodGrainy = FindSelector('grainy');
  const moodHandDrawn = FindSelector('hand drawn');

  const list = (
    <ul>
      <li>
        <div className="nav-link" onClick={() => goMarket(`browse/?moodIds[]=${mood3d}&count=20`)}>3D</div>
      </li>
      <li>
        <div className="nav-link" onClick={() => goMarket(`browse/?moodIds[]=${moodOutline}&count=20`)}>Outline</div>
      </li>
      <li>
        <div className="nav-link" onClick={() => goMarket(`browse/?moodIds[]=${moodFlat}&count=20`)}>Flat</div>
      </li>
      <li>
        <div className="nav-link" onClick={() => goMarket(`browse/?moodIds[]=${moodGrainy}&count=20`)}>Grainy</div>
      </li>
      <li>
        <div className="nav-link" onClick={() => goMarket(`browse/?moodIds[]=${moodHandDrawn}&count=20`)}>Hand drawn</div>
      </li>
      <li>
        <div className="nav-link bordered" onClick={() => goMarket('order-custom')}>Custom work</div>
      </li>
    </ul>
  );

    return (
      <header className={`page-header ${authorizationStatus === true ? `authorized` : ''} `}>
        <Navbar expand="lg">
        <Navbar.Brand className="navbar-brand">
          <div onClick={() => goMarket('')} >
            <StorytaleLogo/>
          </div>
        </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />

          <Navbar.Collapse id="basic-navbar-nav">
            <ul className="navbar-nav header-tools ml-auto">
              <li className="nav-item nav-item-hidden">
                <Collapse ghost>
                  <Panel header="Illustrations" key="1" showArrow={false}>
                    {list}
                  </Panel>
                </Collapse>
              </li>

              {authorizationStatus !== null && subscriptionStatus !== null &&
              <>
                <li className="nav-item dropdown dropdown-browse">
                  <a className="nav-link" href={`${process.env.REACT_APP_STORYTALE_APP}/browse`} onClick={() => goMarket('')}>
                    Illustrations<span className="sr-only">(current)</span>
                  </a>
                  <div className="dropdown-menu browse-content animate slideIn">
                    <div className="browse-content-inner">
                      {list}
                    </div>
                  </div>
                </li>

                <li className="nav-item ">
                  <a href={`${process.env.REACT_APP_STORYTALE_APP}/browse/?packs=true`} className="nav-link" onClick={() => goMarket('browse/?packs=true')}>
                    Packs
                  </a>
                </li>

                <li className="nav-item ">
                  <a href={`${process.env.REACT_APP_STORYTALE_APP}/plans`} className="nav-link" onClick={() => goMarket('plans')}>
                    Pricing
                  </a>
                </li>
                <li className="nav-item nav-item-auth">
                  <Link className="nav-link colored blue" to={`/register`}>
                    Sign Up
                  </Link>
                </li>
                <li className="nav-item nav-item-auth">
                  <Link className="nav-link" to={`/login`}>
                    Sign In
                  </Link>
                </li>
                {subscriptionStatus === false &&
                <li className="nav-item nav-item nav-item-authorized">
                  <div className="nav-link outline blue" onClick={() => goMarket('plans')}>
                    Get full access
                  </div>
                </li>
                }
                <li className="nav-item user-profile dropdown dropdown-user nav-item nav-item-authorized">
                  <Link className="nav-link" to={`/settings`}>
                    <TextAvatar userNameFirstLetter={userEmail && (typeof userEmail === 'string' || userEmail instanceof String) ? userEmail.charAt(0): ''}/>
                  </Link>

                  <div
                    className={`dropdown-menu animate slideIn ${authorizationStatus === true ? `logged-author-content ` : ''} p-0`}>

                    <div className="browse-content-inner">
                      {subscriptionStatus && (membershipStatus === 3 || membershipStatus === 4) && downloadRemaining !== null &&
                      <div className="downloads-count">
                        <div className="total">{downloadRemaining}</div>
                        <div className="description">downloads <br className={'d-none d-md-block'}/> remaining until <br/> {formattedDate}</div>
                      </div>
                      }
                      <ul className="bordered-list">
                        <li>
                          <Link className="nav-link" to={`/likes`}>
                            Likes
                          </Link>
                        </li>
                        <li>
                          <Link className="nav-link" to={`/downloads`}>
                            Downloads
                          </Link>
                        </li>
                      </ul>
                      <ul className="bordered-list">
                        <li>
                          <Link className="nav-link" to={`/settings`}>
                            Settings
                          </Link>
                        </li>
                      </ul>
                      <ul className="bordered-list">
                        <li>
                          <Link className="nav-link red" to={`/logout`} onClick={() => logoutClick()}>
                            Logout
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
              </>
              }
            </ul>
          </Navbar.Collapse>
        </Navbar>
      </header>
    );
};

export default Header ;
