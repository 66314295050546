import React from 'react';
import { Link } from 'react-router-dom';

//lib, helpers
import { ErrorLoger } from '../lib/errorLogger';

import './ErrorBoundary.css';
import MainTitle from "./MainTitle";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      logs: [],
      showReload: false,
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidMount() {
    if (window) {
      this.setState({ showReload: true });
    }
  };

  componentDidCatch(error, errorInfo) {
    let errorLogger = new ErrorLoger();
    let regexp = /\(([^()]+(\([^)]+\)[^)]*)*)\)/g;
    let result = error.stack.match(regexp);

    let fmtd_result = result.map(url => {
      return {
        url: `${url.replace(/[()]/g, '').split('.js')[0]}.js`,
        lineCol: url.replace(/[()]/g, '').split('.js')[1],
      }
    });

    errorLogger.sendError(error.message, errorInfo, fmtd_result, this.props.children.props.location, this.props.children.props);
  }

  forceUpdate() {
    try {
      window.location.reload();
    }
    catch (err) {
      console.log(err);
    }
  }

  render() {
    const { noDisplay } = this.props || false;
    const mainPageUrl = (process.env.REACT_APP_STORYTALE_APP || '/');

    if (this.state.hasError) {
      if (noDisplay) {
        return null;
      }

      return (
        <div className='ErrorBoundary'>
          <div className='container-custom'>
            <div className='row'>
              <div className='col'>
                <MainTitle text={'Oops!'}/>
                <p className='text-center ErrorBoundary__text'>Something went wrong. Please try again later</p>
                {this.state.showReload && <Link onClick={this.forceUpdate}>Reload page</Link>}
                {mainPageUrl === '/'
                  ? <Link to='/' >Go to main page</Link>
                  : <a href={mainPageUrl}>Go to main page</a>
                }
              </div>
            </div>
          </div>
        </div>
      )
    }

    return this.props.children;
  }
  }

  export default ErrorBoundary;
