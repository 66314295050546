const changeIllustrationActivities = (
  id,
  type='isLiked',
  result = null,
  currentIllustrationInfo,
  setCurrentIllustrationInfo,
  customerActivities,
  setCustomerActivities,
) => {

  let customerActivitiesObj = Object.assign({}, customerActivities);

  if(type === 'isLiked') {
    customerActivitiesObj[id] = {
      isLiked: result === 'like' ? true : false,
      isDownloaded: customerActivitiesObj[id] ? customerActivitiesObj[id].isDownloaded : false,
    };
  }

  if(type === 'isDownloaded') {
    customerActivitiesObj[id] = {
      isLiked: customerActivitiesObj[id] ? customerActivitiesObj[id].isLiked : false,
      isDownloaded: result ? true : false,
    };
  }

  setCustomerActivities(customerActivitiesObj);

  // try {
  //   let {likeCount} = currentIllustrationInfo;
  //
  //   if(type === 'isLiked' && result === 'unlike' && likeCount !== 0) {
  //     likeCount -= 1;
  //   } else if (type === 'isLiked' && result === 'like')  {
  //     likeCount += 1;
  //   }
  //
  //   let currentIllustrationInfoObj = Object.assign({}, currentIllustrationInfo);
  //   currentIllustrationInfoObj.likeCount = likeCount;
  //   setCurrentIllustrationInfo(currentIllustrationInfoObj);
  // } catch (error) {
  //
  // }
};

export default changeIllustrationActivities;
