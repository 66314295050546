import axios from 'axios';

export const setAutorizedAction = () => ({
  type: 'SET_AUTHORIZED',
  payload: true
});

export const setUnAutorizedAction = () => {
  localStorage.setItem('isAvtorized', false);
  return {
    type: 'SET_UNAUTHORIZED',
    payload: false
  }
};

export const authorizeAction = () => dispatch => {
  axios.get(`/api/userInfo/validateSession`)
  .then(res => {
    dispatch(setAutorizedAction())
    dispatch(setUserName(res.data.result.user.name))
  })
  .catch( error => {
    dispatch(setUnAutorizedAction())
    dispatch(setUserName(null))
  }) 
}

export const setUserName = (userName) => ({
  type: 'SET_USER_NAME',
  payload: userName,
});
