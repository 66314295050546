import React from "react";
import "./BadgeElement.css";

function BadgeElement(props) {
  const {classes, content , onClick} = props;

  return (
    <span
      className={`badge ${classes}`}
      onClick={(content) => onClick(content)}
    >
      {` ${content} `}
    </span>
  );
}

export default BadgeElement;
